.storybook-calltoaction.card {
  background-color: var(--white-pure);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
  text-align: center;
}

.storybook-calltoaction.card .storybook-button {
  margin-top: 5px;
}

.storybook-calltoaction.card h2 {
  color: var(--blue-purple);
  margin-top: 0;
  margin-bottom: 10px;
}

.storybook-calltoaction.card p {
  color: var(--grey-darker);
}

.storybook-calltoaction.card a {
  color: var(--red);
}

@media (min-width: 768px) {
  .storybook-calltoaction.card {
    padding: 30px 11%;
    margin-bottom: 20px;
  }

  .storybook-calltoaction.card .storybook-button {
    margin-top: 15px;
  }

  .storybook-calltoaction .storybook-button {
    max-width: 200px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .storybook-calltoaction.card h2 {
    font-size: var(--font-size-16);
    line-height: var(--line-spacing-20);
  }

  .storybook-calltoaction.card p {
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-20);
  }
}
