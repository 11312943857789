.storybook-notification {
  align-items: center;
  border-left: 10px solid var(--red);
  border-radius: 4px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  display: flex;
  max-width: 500px;
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-20);
  gap: 10px;
  padding: 8px 20px 8px 10px;
  position: fixed;
  opacity: 0;
  right: -550px;
  top: 58px;
  transition: right 0.7s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.65s ease 0.4s;
}

.storybook-notification--show {
  right: 20px;
  opacity: 1;
}

.storybook-notification .icon-notification {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}

.storybook-notification .icon-info {
  background-image: url('../assets/icon-info.svg');
  background-size: auto;
  width: 12px;
}

.storybook-notification .icon-success {
  background-image: url('../assets/icon-success.svg');
}

.storybook-notification .icon-warning {
  background-image: url('../assets/icon-warning.svg');
}

.storybook-notification .icon-error {
  background-image: url('../assets/icon-error.svg');
}