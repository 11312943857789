/******************************************************* 
 * Global styles based on Calbright Design Style Guide 
 ******************************************************* 
 */

:root {
  /* Colors */
  --purple: #a333c8;
  --orange: #f2711c;
  --olive: #b5cc18;
  --teal: #00b5ad;
  --violet: #6435c9;
  --pink: #e03997;
  --brown: #a5673f;
  --black: #1b1c1d;

  /* Greens */
  --green-lightest: #90dfaa;
  --green-lighter: #43be98;
  --green: #0da81f;
  --green-dark: #0a8a19;
  --green-darker: #006a00;
  --green-darkest: #206406;
  --green-border: #0da81f;
  --green-text: #0a8a19;
  --orange-border: #eda214;

  /* Blues */
  --blue-purple: #001e5a;
  --blue-light: #84dbff;
  --blue: #3f87d4;
  --blue-darker: #0050bc;
  --blue-darkest: #324a5e;

  /* Background Colors */
  --white-pure: #ffffff;
  --cream-light: #fff7e8;
  --tan: #f2dfc2;

  /* Grey Scale */
  --grey-darker: #333333;
  --grey-dark: #555555;
  --grey: #747474;
  --grey-light: #999999;
  --grey-lighter: #cccccc;
  --grey-disabled: #dddddd;
  --grey-btn: #e0e1e2;
  --grey-brightest: #f8f8f9;

  /* Yellows, Browns and Additional Red */
  --yellow: #f5bb11;
  --yellow-dark: #ff9d26;
  --brown: #be7743;
  --red: #d34508;
  --red-dark: #be5143;

  --border-color: #cccccc;
  --button-default-hover: #d96736;
  --button-default-active: #b93800;
  --button-color-disabled: #bbbbbb;
  --button-bg-disabled: #eeeeee;
  --icon-color: #999999;
  --hover-row: #f3f3f3;
  --chat-head: #ffe3b7;
  --chat-head-hover: #ffd280;
  --chat-text-head: #555555;

  /* Career colors */
  --career-red: #be5143;
  --career-yellow: #ff9d26;
  --career-blue: #0050bc;
  --career-green: #0da820;

  /* Alert colors*/
  --info: #0e566c;
  --info-border: #bedfe6;
  --info-background: #f8ffff;
  --warning: #7a4d05;
  --warning-border: #ccbea0;
  --warning-background: #fffaf3;
  --success: #1e561f;
  --success-border: #a8c599;
  --success-background: #fcfff5;
  --error: #973937;
  --error-border: #973937;
  --error-background: #fff6f6;

  /* Font/text values */
  --font-family-lato: "Lato", sans-serif;

  --font-style-normal: normal;
  --font-style-italic: italic;
  --font-style-oblique: oblique;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 900;

  /* Font sizes */
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-13: 0.8125rem;
  --font-size-14: 0.875rem;
  --font-size-15: 0.9375rem;
  --font-size-16: 16px; /* 16px; */
  --font-size-18: 1.125rem;
  --font-size-24: 1.5rem;
  --font-size-28: 1.75rem;

  /* Character/line spacing */
  --character-spacing-0: 0px;
  --line-spacing-16: 16px;
  --line-spacing-18: 18px;
  --line-spacing-19: 19px;
  --line-spacing-20: 20px;
  --line-spacing-22: 22px;
  --line-spacing-23: 23px;
  --line-spacing-24: 24px;
  --line-spacing-30: 30px;
  --line-spacing-36: 36px;
}

.theme-cold {
  --red: #065e84;
  --cream-light: #f0f5fa;
  --grey-dark: #333333;
  --grey: #555555;
  --border-color: #555555;
  --icon-color: #555555;
  --blue-darker: #004094;
  --green: #076413;
  --green-text: #076413;
  --green-border: #076413;
  --green-darker: #005200;
  --red-dark: #943e33;
  --yellow-dark: #854900;
  --button-default-hover: #065e84;
  --button-default-active: #004094;
  --hover-row: #fcfcfc;
  --tan: #dddddd;

  --career-red: #943e33;
  --career-yellow: #854900;
  --career-blue: #004094;
  --career-green: #076413;
  --chat-head: #065e84;
  --chat-head-hover: #004094;
  --chat-text-head: #ffffff;
}

* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: var(--font-family-lato), sans-serif;
  background-color: var(--cream-light);
}

a {
  color: var(--red);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button:focus-visible,
a:-webkit-any-link:focus-visible {
  outline: none;
}

.storybook-notice {
  color: var(--grey-dark);
  margin-bottom: 25px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.head-thin {
  font-weight: normal;
  color: var(--grey-darker);
  line-height: 1.6;
}

/* Different lato font weights from 100 to 900 */
.lato-thin {
  font-weight: 100;
  font-style: normal;
}

.lato-thin-italic {
  font-weight: 100;
  font-style: italic;
}

.lato-light {
  font-weight: 300;
  font-style: normal;
}

.lato-light-italic {
  font-weight: 300;
  font-style: italic;
}

.lato-regular {
  font-weight: 400;
  font-style: normal;
}

.lato-regular-italic {
  font-weight: 400;
  font-style: italic;
}

.lato-bold {
  font-weight: 700;
  font-style: normal;
}

.lato-bold-italic {
  font-weight: 700;
  font-style: italic;
}

.lato-black {
  font-weight: 900;
  font-style: normal;
}

.lato-black-italic {
  font-weight: 900;
  font-style: italic;
}

.storybook-modal .error,
.error {
  color: var(--red-dark);
}

/* Character Styles */
h1,
.h1 {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-28);
  line-height: var(--line-spacing-36);
  letter-spacing: var(--character-spacing-0);
}

h2,
.h2,
.header {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-24);
  line-height: var(--line-spacing-30);
  letter-spacing: var(--character-spacing-0);
}

h3,
.h3 {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-23);
  letter-spacing: var(--character-spacing-0);
}

h4,
.h4 {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-19);
  letter-spacing: var(--character-spacing-0);
}

h5,
.h5 {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-18);
  letter-spacing: var(--character-spacing-0);
}

p,
.paragraph {
  font-family: var(--font-family-lato);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
  margin-top: 0;
  margin-bottom: 10px;
}

.page-title {
  margin: 20px 0;
}

.page-title h1,
.page-title h2 {
  color: var(--grey-darker);
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-30);
  margin: 0 0 10px 0;
}

.page-title p {
  color: var(--grey-darker);
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-20);
  letter-spacing: 0px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .page-title {
    margin: 30px 0 20px;
  }

  .page-title h1,
  .page-title h2 {
    font-size: var(--font-size-24);
  }

  .page-title p {
    font-size: var(--font-size-14);
  }
}

.container {
  box-sizing: border-box;
  max-width: 1250px;
  margin: auto;
  padding: 0 10px;
  width: 100%;
}

.subheader {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-22);
  letter-spacing: var(--character-spacing-0);
}

.description {
  font-family: var(--font-family-lato);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-13);
  line-height: var(--line-spacing-18);
  letter-spacing: var(--character-spacing-0);
}

.table-header {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-900);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
}

.table-body {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
}

.description-muted {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-13);
  line-height: var(--line-spacing-16);
  letter-spacing: var(--character-spacing-0);
}

.card-small-user-title {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-13);
  line-height: var(--line-spacing-23);
  letter-spacing: var(--character-spacing-0);
}

.time-and-datestamp-muted {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-16);
  letter-spacing: var(--character-spacing-0);
}

.comment-body {
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-16);
  letter-spacing: var(--character-spacing-0);
}

.disabled {
  color: var(--grey-disabled);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
