.storybook-avatar {
  position: relative;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  align-self: center;

  font-family: var(--font-family-lato);
  color: var(--grey-darker);

  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  line-height: 1.8em;
  border: 1px solid var(--white-pure);
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  -moz-box-align: center;
  
  text-align: center;
  letter-spacing: 0.015em;
 
  background-color: var(--grey-btn);

  --avatar-size: 32px;
}

.storybook-avatar.active,
.storybook-avatar:hover {
  border-color:var(--red);
}

.storybook-avatar:hover {
  cursor: pointer;
}

.storybook-avatar--small {
  height: calc(var(--avatar-size)*0.8);
  width: calc(var(--avatar-size)*0.8);
  font-size: calc(var(--font-size-12)*0.8);
}

.storybook-avatar--medium {
  height: var(--avatar-size);
  width: var(--avatar-size);
  font-size: var(--font-size-12);
}

.storybook-avatar--large {
  height: calc(var(--avatar-size)*1.2);
  width: calc(var(--avatar-size)*1.2);
  font-size: calc(var(--font-size-12)*1.2);
}

.storybook-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}