.pre-req-btns {
  margin: 60px 0 10px;
  width: 480px;
}

.pre-req-btns button {
  margin-bottom: 10px;
  text-align: left;
}

.mt-30 {
  margin-top: 30px;
}