.upload-input {
  align-items: center;
  font-size: var(--font-size-14);
  padding: 14px 0;
  position: relative;
  display: flex;
}

.upload-input,
.upload-input + .error {
  max-width: 600px;
  margin: auto;
}

.upload-input * {
  position: relative;
}

.upload-input::before {
  display: none;
  border-radius: 4px;
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  left: -14px;
  right: -14px;
  top: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 0;
}

.upload-input.over::before {
  border: 1px dashed var(--red);
  display: block;
  opacity: 1;
}

.upload-input-inner {
  align-items: center;
  display: flex;
}

.upload-input-name {
  color: var(--grey-darker);
  margin-right: 34px;
  text-transform: capitalize;
}

.upload-input-placeholder {
  align-items: center;
  color: var(--grey-light);
  display: flex;
}

.upload-input-type-simple {
  border: 1px solid var(--grey-light);
  line-height: 20px;
  border-radius: 4px;
  width: 400px;
  height: 35px;
  padding: 5px 10px;
  margin: 5px;
  display: inline-flex;
  font-size: var(--font-size-14);
  color: var(--grey-light);
}

.upload-input-placeholder span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.attached .upload-input-placeholder {
  color: var(--grey-darker);
  font-weight: bold;
  overflow: hidden;
}

.upload-input-status {
  align-items: center;
  border: 1px solid var(--grey-light);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 16px;
  margin-right: 10px;
  width: 16px;
}

.upload-input-status img {
  cursor: pointer;
}

.attached .upload-input-status {
  background-color: var(--red);
  border-color: var(--red);
}

.upload-input .storybook-button {
  margin-left: auto;
}

.upload-delete {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  margin: 0 8px;
  padding: 0;
  position: relative;
  top: 5px;
}

.upload-input-type-simple .upload-delete {
  position: absolute;
  right: 0;
  margin-top: 2px;
}

.upload-delete svg {
  display: block;
  font-size: 1.2rem;
  fill: #777777;
}

.upload-error {
  text-align: center;
  color: var(--red-dark);
}

.upload-optional {
  border: 1px solid var(--grey-lighter);
  border-radius: 4px;
  color: var(--grey-darker);
  height: 38px;
  max-width: 110px;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  font-size: var(--font-size-14);
}

.upload-optional:focus-visible {
  border: 1px solid var(--red);
  outline: none;
}

.upload-input-col {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 767px) {
  .upload-input {
    align-items: flex-start;
    flex-direction: column;
    padding-left: 14px;
    padding-right: 14px;
    gap: 12px;
  }

  .upload-input-placeholder {
    margin-top: 8px;
    flex-wrap: wrap;
    width: 100%;
  }

  .upload-input-placeholder span {
    flex: 1 0 80%;
    max-width: 320px;
  }

  .upload-optional {
    margin-bottom: 12px;
  }

  .upload-input .storybook-button {
    position: absolute;
    right: 14px;
    top: 14px;
  }

  .upload-input-col {
    margin-top: 10px;
  }
}

@media screen and (max-width: 414px) {
  .upload-input .storybook-button {
    position: unset;
    margin-left: unset;
  }
}
