.storybook-task-progress-wrapper {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.storybook-task-progress-bar {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 20px;
  width: 100%;
}

.storybook-task-progress-bar .storybook-task-item {
  position: relative;
}

.storybook-task-progress-line__default,
.storybook-task-progress-line__progress {
  height: 2px;
}

.storybook-task-progress-line__default {
  background-color: var(--border-color);
  position: absolute;
  top: 23px;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
}

.storybook-task-progress-wrapper--completion .storybook-task-progress-line__default {
  top: 45px;
}

.storybook-task-progress-line__progress {
  background-color: var(--green-border);
}

.storybook-task-progress-bar + strong {
  color: var(--grey-darker);
  font-size: var(--font-size-12);
  font-weight: var(--font-size-28);
  font-weight: var(--font-weight-extra-bold);
  display: block;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .storybook-task-progress-bar {
    flex-direction: column;
  }

  .storybook-task-progress-bar .storybook-task-item {
    margin-bottom: 20px;
  }

  .storybook-task-progress-line__default {
    left: 50px;
    transform: translateX(-50%);
    width: 2px;
    height: 90%;
  }

  .storybook-task-progress-wrapper--completion .storybook-task-progress-line__default {
    top: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .storybook-task-progress-bar .storybook-task-item {
    margin-bottom: 10px;
  }
  .storybook-task-progress-wrapper,
  .storybook-task-progress-bar {
    margin-bottom: unset;
  }
  .storybook-task-progress-line__default {
    left: 25px;
  }
}