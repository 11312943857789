.multi-file {
  max-width: 600px;
  margin: auto;
  width: 100%;
}

.multi-file h2 {
  font-weight: normal;
  line-height: 40px;
}

.multi-file .storybook-notice {
  margin: 15px 0 30px;
}

.multi-file p {
  color: var(--grey-dark);
}

.multi-file-btn-area {
  text-align: center;
}

.multi-file-btn-area button {
  border-radius: 4px;
  width: 120px;
}