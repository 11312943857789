.storybook-tooltip-container {
  position: relative;
  display: inline-block;
  cursor:default;

  /* Tooltip shared styles */
  --tt-font-size: var(--font-size-16);
  --tt-width: 150px;
  --tt-border-color: var(--grey-disabled);
  --tt-bg-color: var(--white-pure);
  --tt-border-bottom-color: var(--grey-normal);

  font-family: var(--font-family-lato);
  font-family: var(--font-family-lato);
  font-size: var(--tt-font-size);
}

@media (max-width: 1366px) {
  .storybook-tooltip-container {
    --tt-width: 200px;
  }
}
@media (max-width: 768px) {
  .storybook-tooltip-container {
    --tt-width: 150px;
  }
}

@media (max-width: 320px) {
  .storybook-tooltip-container {
    --tt-width: 100px;
  }
}

.storybook-tooltip--underline {
  border-bottom: 1px dotted var(--grey-normal);
}

.storybook-tooltip-container .storybook-tooltip-text {
  visibility: hidden;
  display:inline;
  position: absolute;
  background: var(--tt-bg-color) 0% 0% no-repeat padding-box;
  background: var(--tt-bg-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid var(--tt-border-color);
  border-radius: 6px;
  padding: 0.8em;
  font-size:0.8em;
  min-width: var(--tt-width);
  max-width: calc(var(--tt-width)*2);
}

.storybook-tooltip-container:hover .storybook-tooltip-text {
  visibility: visible;
}

/* Tooltip arrow general positioning */

.storybook-tooltip-text--right {
  left: 110%;
}

.storybook-tooltip-text--left  {
  right: 110%;
}

.storybook-tooltip-text--top  {
  top: -180%;
  left: 50%;
  margin-left: -60px; 
}

.storybook-tooltip-text--bottom  {
  top: 130%;
  left: 50%;
  margin-left: -60px; 
  margin-top:5px;
}

/* Bottom arrow */

.storybook-tooltip-text--bottom::after,
.storybook-tooltip-text--bottom::before {
  content: " ";
	position: absolute;
	left: 20%;
  bottom: 100%;
}

.storybook-tooltip-text--bottom::after {
	margin-left: -6px;
  border:8px solid transparent;
  border-bottom-color: var(--tt-bg-color)
}

.storybook-tooltip-text--bottom::before {
  bottom: 100%;
  margin-left: -8px;
  border: 10px solid transparent;
  border-bottom-color: var(--tt-border-color);
}

/* Top arrow */
.storybook-tooltip-text--top::after,
.storybook-tooltip-text--top::before {
  content: " ";
	position: absolute;
	left: 20%;
}

.storybook-tooltip-text--top::before {
  top: 100%;
  margin-left: -6px;
  border: 8px solid transparent;
  border-top-color: var(--tt-border-color);
}

.storybook-tooltip-text--top::after {
  top: 90%;
	margin-left: -8px;
  border: 10px solid transparent;
  border-top-color: var(--tt-bg-color);
}

/* Right arrow */
.storybook-tooltip-text--right::after,
.storybook-tooltip-text--right::before {
  content: " ";
	position: absolute;
	right: 100%;
  top: 10%;
}

.storybook-tooltip-text--right::before {
  margin-top: 6px;
  border: 8px solid transparent;
  border-right-color: var(--tt-bg-color);
  z-index:2;
}

.storybook-tooltip-text--right::after {
	margin-top: 4px;
	border: 10px solid transparent;
  border-right-color: var(--tt-border-color);
}

/* Left arrow */
.storybook-tooltip-text--left::after,
.storybook-tooltip-text--left::before {
  content: " ";
	position: absolute;
	left: 100%;
  top: 10%;
}

.storybook-tooltip-text--left::before {
  margin-top: 6px;
  border: 8px solid transparent;
  border-left-color: var(--tt-bg-color);
  z-index:2;
}

.storybook-tooltip-text--left::after {
	margin-top: 4px;
	border: 10px solid transparent;
  border-left-color: var(--tt-border-color);
}