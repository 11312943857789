.storybook-help header svg[data-testid="QuestionMarkOutlinedIcon"],
.storybook-icon-container svg[data-testid="QuestionMarkOutlinedIcon"]:hover {
  color: var(--red);
  outline: 2px solid var(--red);
}

.storybook-help-content {
  display: flex;
  gap: 20px;
}

.storybook-help-search {
  border-radius: 8px;
  background-color: var(--tan);
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.storybook-help-search h2 {
  font-size: var(--font-size-18);
  color: var(--blue-purple);
  margin: 0 0 20px 0;
  width: auto;
}

.storybook-help .storybook-search-container {
  border: 2px solid var(--white-pure);
  max-width: 100%;
  max-height: 40px;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

.focused .storybook-search-container {
  border-color: var(--red);
}

.storybook-help-search .storybook-search-icon {
  border-radius: 50%;
  box-sizing: content-box;
  fill: var(--grey-dark);
  padding: 6px 16px 6px 8px;
  order: 2;
  transition: all 0.2s ease-in-out;
}

.storybook-help-search .storybook-search-icon:hover,
.focused .storybook-search-icon {
  fill: var(--red);
}

.storybook-help-search .storybook-search-input {
  border-radius: 25px;
  border-width: 0;
  border-color: transparent;
  color: var(--grey-darker);
  padding: 8px 14px 10px;
  height: 36px;
}

.storybook-help-search .storybook-search-input::placeholder {
  color: var(--grey-light);
}

.storybook-help-search-wrapper {
  padding: 20px 20px 30px 20px;
}

.storybook-help-search-results {
  background-color: var(--white-pure);
  border-radius: 0 0 8px 8px;
}

.storybook-help-search-results.box-out.no-results,
.storybook-help-search-results.box-out,
.storybook-help-search-results.box-inner {
  padding: 20px;
}

.storybook-help-search-results-back {
  color: var(--red);
  cursor: pointer;
  font-weight: bold;
  gap: 7px;
  padding-bottom: 20px;
  margin: 0;
}

.storybook-help-search-results-items {
  color: var(--grey);
  font-weight: bold;
  line-height: var(--line-spacing-16);
  margin: 0;
}

.storybook-help-categories .storybook-app-badge,
.storybook-help-search-noresults-categories .storybook-app-badge {
  border: 1px solid var(--grey-disabled);
  border-radius: 6px;
  margin: unset;
}

.storybook-help-categories.box-out .storybook-app-badge,
.storybook-help-search-noresults-categories .storybook-app-badge {
  padding: 1rem;
}

.storybook-help-search-noresults {
  text-align: center;
  margin: 10px 0 50px;
}

.storybook-help-search-noresults h2 {
  color: var(--grey-darker);
  font-size: var(--font-size-18);
}

.storybook-help-search-noresults p {
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-20);
  color: var(--grey-dark);
}

.storybook-help-categories .storybook-app-badge {
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.storybook-help-search-results.no-results .storybook-app-badge,
.storybook-help-search-results.box-inner .storybook-app-badge {
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.storybook-help-search-results.box-inner .storybook-app-badge {
  flex-direction: row;
}

.storybook-help-search-results.box-inner .storybook-app-badge--copy {
  line-height: var(--line-spacing-20);
  text-align: left;
}

.storybook-help-categories .storybook-app-badge:hover,
.storybook-help-categories .storybook-app-badge[active="true"],
.storybook-help-search-noresults-categories .storybook-app-badge:hover {
  border: 1px solid var(--red);
  background: var(--button-bg-disabled);
  cursor: pointer;
}

.storybook-help-search-results.box-inner.storybook-app-badge[active="true"] img,
.storybook-help-search-noresults-categories .storybook-app-badge img {
  height: 32px;
  margin: 0;
  min-width: unset;
  min-height: unset;
  width: 32px;
}

.storybook-help-categories .storybook-app-badge[active="true"] span,
.storybook-help-categories .storybook-app-badge.active-sibling span,
.storybook-help-search-noresults-categories .storybook-app-badge span {
  text-align: left;
  margin-bottom: 0.5rem;
  font-size: var(--font-size-12);
}

.storybook-help-search-noresults-categories .storybook-app-badge span {
  font-weight: bold;
}

.storybook-help-search-results.box-out:not(.no-results)
  .storybook-app-badge
  img {
  margin-bottom: 20px;
  min-height: 100px;
  min-width: 100px;
}

.storybook-help-categories .storybook-app-badge span {
  color: var(--grey-darker);
  font-size: var(--font-size-14);
  font-weight: bold;
}

.storybook-help-aside {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--white-pure) 0% 0% no-repeat padding-box;
  padding: 30px 20px;
  text-align: center;
  width: 100%;
}

.storybook-help-aside h2,
.storybook-help-aside h4 {
  color: var(--blue-purple);
  margin: 0 0 10px 0;
}

.storybook-help-aside h2 {
  line-height: var(--line-spacing-30);
}

.storybook-help-aside-block p {
  color: var(--grey-dark);
  font-size: 14px;
  line-height: var(--line-spacing-20);
}

.storybook-help-aside-block--contact-us p {
  color: var(--grey-darker);
}

.storybook-help-aside-block p + p {
  margin-bottom: 5px;
}

.storybook-help-aside hr {
  border-top-color: var(--grey-disabled);
  margin: 30px 0;
}

.storybook-help-aside a,
.storybook-help-aside a:visited,
.storybook-help .accordion-content a {
  color: var(--red);
}

.storybook-help-aside .storybook-button {
  border-radius: 6px;
  font-weight: bold;
  width: 120px;
}

.storybook-help-aside-block {
  max-width: 200px;
  margin: 0 auto 50px;
}

.storybook-help .accordion-content,
.storybook-help .accordion-title h4 {
  font-size: var(--font-size-14);
}

.storybook-help .accordion-content p {
  font-size: var(--font-size-12);
  margin: 0;
}

.storybook-help .accordion > h4 {
  margin-bottom: 20px;
}

.storybook-help .accordion {
  padding: 20px 0px 5px;
}

.storybook-help .accordion-title {
  padding: 15px 40px 15px 20px;
}

.storybook-help .accordion-item:last-child .accordion-title {
  border-bottom: 1px solid var(--grey-disabled);
}

.storybook-help .accordion-content {
  padding: 20px;
}

.storybook-help-search-suggestion-category {
  background-color: #f7f7f7;
  border-color: var(--grey-disabled);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: var(--blue-purple);
  grid-area: category;
  margin: 0;
  padding: 10px 0 10px 20px;
}

.storybook-help-search-suggestion-item:last-child div p:last-child,
.storybook-help-search-suggestion-item:last-child h4 {
  border-bottom-width: 0;
}

.storybook-help-search-suggestion-questions {
  grid-area: questions;
}
.storybook-help-search-suggestion-question,
.storybook-help-search-suggestions div p {
  margin-bottom: 0;
}

.storybook-help-search-suggestion-item {
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-areas: "category questions";
  grid-template-rows: auto;
}
.storybook-help-search-suggestion-item:last-of-type
  .storybook-help-search-suggestion-category:last-of-type {
  border-radius: 0 0 0 8px;
}
.storybook-help-search-suggestion-item:last-of-type
  .storybook-help-search-suggestion-questions:last-of-type {
  border-radius: 0 0 8px 0;
}

.storybook-help-search-suggestions,
.storybook-help-search-quicklinks {
  display: block;
  position: absolute;
  top: 100px;
  left: 20px;
  right: 20px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  background-color: var(--white-pure);
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
}

.storybook-help-search-suggestions h3,
.storybook-help-search-quicklinks h3 {
  color: var(--grey-light);
  font-size: var(--font-size-14);
  margin: 0;
  padding: 8px 20px;
  border-bottom: 1px solid #eeeeee;
}
.storybook-help-search-suggestions p,
.storybook-help-search-quicklinks p {
  align-items: center;
  display: flex;
  min-height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid var(--button-bg-disabled);
  cursor: pointer;
  font-size: var(--font-size-14);
  white-space: pre-wrap;
}

.storybook-help-search-suggestion-questions p:last-child {
  border-bottom-color: var(--grey-disabled);
}
.storybook-help-search-suggestions p:hover,
.storybook-help-search-quicklinks p:hover {
  background-color: var(--button-bg-disabled);
}

.storybook-help-search-suggestions div p,
.storybook-help-search-quicklinks p {
  font-weight: bold;
  font-size: var(--font-size-12);
  margin-bottom: 0;
}

.storybook-help-search-quicklinks p {
  color: var(--grey-darker);
}

.storybook-help-search-suggestions div p {
  color: var(--grey);
}

.storybook-help-search-suggestions div p span {
  color: var(--grey-darker);
}

.storybook-help-search-suggestions div p {
  margin-bottom: 0;
}

.walkthrough-link {
  align-items: center;
  display: flex;
  font-size: var(--font-size-14);
  gap: 5px;
  margin: auto;
  width: fit-content;
}

.icon-rocket {
  display: block;
  height: 16px;
  width: 16px;
}

.icon-live-chat,
.icon-mail-support,
.icon-phone-support {
  display: block;
  margin: 0 auto 10px;
  height: 48px;
  width: 48px;
}

.storybook-help-search-results .left-arrow,
.storybook-help-search-results .right-arrow {
  display: none;
}

.left-arrow,
.right-arrow {
  all: unset;
  cursor: pointer;
}
.left-arrow svg,
.right-arrow svg {
  border: 1px solid transparent;
  border-radius: 1em;
}
.left-arrow svg:hover,
.right-arrow svg:hover {
  color: var(--red);
  background-color: var(--grey-btn);
}

.arrow-disabled svg {
  fill: var(--grey-lighter);
}
.arrow-disabled svg:hover {
  color: unset;
  background-color: unset;
}
.right-arrow.arrow-disabled,
.left-arrow.arrow-disabled {
  cursor: unset;
}

.storybook-help address {
  display: inline-block;
  font-style: normal;
}

@media screen and (min-width: 992px) {
  .storybook-help-search-results.box-out .storybook-app-badge {
    width: clamp(100px, 266px, 36.8%);
  }
  .storybook-help-search-results.box-inner .storybook-app-badge--copy {
    max-width: 70px;
  }
  .storybook-help-search-results.box-out .storybook-help-categories--inner {
    width: 880px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .storybook-help-search-results.box-inner .storybook-help-categories--wrapper {
    overflow-x: hidden;
  }
  .storybook-help-search-results.box-out .storybook-help-categories--wrapper {
    overflow-x: unset;
  }
  .storybook-help-search-results.box-inner .storybook-help-categories--inner {
    max-width: 800px;
  }
  .storybook-help-search-results.box-inner
    .storybook-help-categories:-webkit-scrollbar {
    display: none;
  }
  .storybook-help-search-results.box-out
    .storybook-help-categories
    .storybook-app-badge {
    max-width: 230px;
  }
  .storybook-help-search-results.box-inner
    .storybook-help-categories
    .storybook-app-badge {
    min-width: 190px;
  }
  .storybook-help-search-results.box-inner .left-arrow,
  .storybook-help-search-results.box-inner .right-arrow {
    display: block;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .storybook-help-search-results.box-out .storybook-app-badge {
    width: clamp(100px, 320px, 43.8%);
  }

  .storybook-help-search-results.box-inner .storybook-app-badge {
    flex-direction: column;
  }

  .storybook-help-search-results.box-inner .storybook-app-badge--copy {
    text-align: center;
  }
}

/* media query for desktop */
@media (min-width: 768px) {
  .storybook-help-search-results.box-inner .storybook-app-badge img {
    height: 48px;
    width: 48px;
  }

  .storybook-help-search-results .storybook-help-categories--inner {
    column-gap: 10px;
  }

  .storybook-help-search-results.box-inner .storybook-app-badge,
  .storybook-help-search-noresults-categories .storybook-app-badge {
    justify-content: center;
    width: 21%;
  }

  .storybook-help-search-results .storybook-help-categories,
  .storybook-help-search-results .storybook-help-categories--inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .storybook-help-search-results .storybook-help-categories--inner {
    gap: 10px;
  }
  .storybook-help-search-results.box-out .storybook-help-categories--inner {
    gap: 20px;
  }

  .storybook-help-search-results .left-arrow {
    margin-right: 10px;
  }

  .storybook-help-search-results.box-out {
    padding: 50px 20px;
  }

  .storybook-help-search-results.box-out .storybook-help-categories {
    gap: 30px;
  }

  .storybook-help-search-suggestions div p,
  .storybook-help-search-quicklinks p {
    font-size: var(--font-size-14);
  }

  .storybook-help-search-results.box-out .storybook-app-badge {
    height: 200px;
    justify-content: center;
  }

  .storybook-help-categories .storybook-app-badge span {
    font-size: var(--font-size-16);
  }

  .storybook-help-categories .storybook-app-badge.active-sibling {
    order: 1;
  }

  .storybook-help-search-noresults {
    margin: 10px 0 80px;
  }

  .storybook-help-search-noresults h2 {
    font-size: var(--font-size-24);
  }

  .storybook-help-search-noresults-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .storybook-help-search
    .storybook-help-search-noresults-categories
    .storybook-app-badge
    img {
    min-height: 72px;
    min-width: 72px;
  }

  .storybook-help-search
    .storybook-help-search-noresults-categories
    .storybook-app-badge {
    width: clamp(100px, 320px, 22.4%);
    height: 148px;
    display: flex;
    justify-content: center;
  }

  .storybook-help .accordion {
    width: 100%;
    order: 5;
  }

  .storybook-help .accordion-content {
    padding: 30px 40px;
  }

  .storybook-help .accordion-content p {
    font-size: var(--font-size-14);
  }

  .storybook-help-search-results-back {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .storybook-help-aside {
    flex: 1 0 290px;
  }
}

@media (max-width: 767px) {
  .storybook-help-content {
    flex-direction: column;
  }

  .storybook-help .accordion {
    padding-bottom: 0;
    margin-bottom: 20px;
  }

  .storybook-help .accordion-title {
    padding-left: 10px;
  }

  .storybook-help-search-results.box-inner
    .storybook-app-badge:not(:last-child),
  .storybook-help-search-noresults-categories
    .storybook-app-badge:not([active="true"]) {
    margin-bottom: 10px;
  }

  .storybook-help-search-results.box-out:not(.no-results) .storybook-app-badge {
    padding: 20px;
    flex-direction: column;
    margin: 0 auto 20px;
    width: 260px;
  }

  .storybook-help-search-suggestions div p,
  .storybook-help-search-quicklinks p {
    line-height: var(--line-spacing-20);
  }

  .storybook-help-search-results.box-out .storybook-app-badge:last-child,
  .storybook-help-search-noresults-categories .storybook-app-badge:last-child {
    margin-bottom: 0;
  }

  .storybook-help-search-results.box-out:not(.no-results)
    .storybook-app-badge
    img {
    margin: 0 auto 20px;
  }
}
@media only screen and (max-width: 767px) {
  .storybook-help-search-results.box-inner
    .storybook-app-badge.active-sibling[active="false"] {
    display: none;
  }
}
