.storybook-schedule .storybook-alert {
  max-width: 1180px;
  margin: 10px auto;
  border: 1px solid;
  border-radius: 4px;
  max-width: 837px;
}

.storybook-schedule .storybook-alert > * {
  padding-inline: 28px;
}

.storybook-schedule .storybook-alert > *:nth-child(2) {
  padding-top: 16px;
}

.storybook-schedule .storybook-alert > *:last-child {
  margin-bottom: 16px;
}

.storybook-schedule .storybook-alert--warning {
  border-color: #ccbea0;
  background-color: #fffaf3;
  color: #7a4d05;
  --warning: #7a4d05;
  color: var(--warning);
}

.storybook-schedule .storybook-alert--info {
  border-color: #bedfe6;
  background-color: #f8ffff;
  --info: #0e566c;
  color: var(--info);
}

.storybook-schedule .storybook-alert--success {
  border-color: #a8c599;
  background-color: #fcfff5;
  --success: #1e561f;
  color: var(--success);
}

.storybook-schedule .storybook-alert--error {
  border-color: #973937;
  background-color: #fff6f6;
  color: #973937;
  --error: #973937;
  color: var(--error);
}

.storybook-schedule .storybook-alert header {
  font-size: var(--font-size-18);
}

.storybook-schedule .storybook-alert p {
  font-size: var(--font-size-14);
  padding-block: 10px;
  margin-bottom: 0;
}

.storybook-schedule .storybook-alert p {
  padding-bottom: 10px;
}

.storybook-schedule .storybook-alert-close {
  border: 0;
  background-color: transparent;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  padding: 0;
}
.storybook-schedule .storybook-alert-close svg {
  font-size: var(--font-size-14);
}

.storybook-schedule .storybook-alert-close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.storybook-schedule
  .storybook-alert--info
  .storybook-schedule
  .storybook-alert-close
  svg {
  fill: var(--info);
}
.storybook-schedule
  .storybook-alert--success
  .storybook-schedule
  .storybook-alert-close
  svg {
  fill: var(--success);
}
.storybook-schedule
  .storybook-alert--warning
  .storybook-schedule
  .storybook-alert-close
  svg {
  fill: var(--warning);
}
.storybook-schedule
  .storybook-alert--error
  .storybook-schedule
  .storybook-alert-close
  svg {
  fill: var(--error);
}

.storybook-schedule .storybook-alert .storybook-button {
  margin-bottom: 16px;
  margin-inline: 28px;
}

.back-button {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
