.storybook-modal::backdrop {
    background: #242831 0% 0% no-repeat padding-box;
    opacity: 0.95;
}

.storybook-modal {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border:0;
    border-radius: 4px;
    white-space: break-spaces;
}
/* 
.storybook-modal::before {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  position: fixed;
  background-color: rgba(0,0,0,.6);
  z-index: 0;
} */

.storybook-modal--small {
    width: 220px !important;
    min-height: 268px;
}

.storybook-modal--medium {
    width:573px;
}

.storybook-modal--large {
    min-height: 268px;
    width: 720px;
}

.storybook-modal--extra-large {
    box-sizing: border-box;
    max-width: 100%;
    width: 860px;
}

.storybook-modal-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.storybook-modal-close {
    border: 0;
    background-color: transparent;

}

.storybook-modal-close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.storybook-modal-close svg {
    color: #00000099;
}

.storybook-modal-body {
    max-width:80%;
    font-weight: var(--font-weight-bold);
    font-size:var(--font-size-14);
    color: var(--grey-dark);
    line-height: 1.5em;
    margin: 0 auto;
    padding-bottom: 2em;
}
.storybook-modal-body p {
    font-weight: var(--font-weight-regular);
    font-size:var(--font-size-14);
    color: var(--grey-dark);
}

.storybook-modal .storybook-modal-title {
    font-size: var(--font-size-28);
    font-weight: var(--font-weight-bold);
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: var(--grey-darker);
    line-height: 2.13rem;
}

.storybook-modal-footer {
    display: flex;
    justify-content: center;
    padding-bottom:2em;
}
.storybook-modal-footer button {
    margin:0.5em;
}

.storybook-modal-icon-container {
    width:100%;
    display: flex;
    justify-content: center;
}