.storybook-accessibility .accordion {
  background: transparent;
}

.storybook-accessibility .accordion {
  padding: 30px 0 0 0;
}
.storybook-accessibility .accordion button h4 {
  text-align: left;
}

.storybook-accessibility .resources-left,
.storybook-accessibility .resources-right {
  overflow-x: inherit;
  overflow-y: inherit;
}

.storybook-accessibility .resources-right {
  margin-bottom: 20px;
  position: relative;
}

.storybook-accessibility .resources h1 {
  font-size: var(--font-size-16);
  color: var(--grey-darker);
}

.storybook-accessibility .storybook-team > h2 {
  margin-top: 0;
}

.storybook-accessibility .resources a:hover {
  text-decoration: underline;
}

.storybook-accessibility .resources-row img {
  height: auto;
}

.text-blue {
  color: var(--blue);
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pb-15 {
  padding-bottom: 15px;
}

.mt-0 {
  margin-top: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .storybook-accessibility .resources h1 {
    font-size: var(--font-size-24);
  }

  .storybook-accessibility .resources-right img.resource-bg {
    opacity: 0.2;
  }

  .storybook-accessibility .resources-left {
    height: auto;
    margin-bottom: unset;
  }
}

@media (max-width: 991px) {
  .storybook-accessibility .resources-right {
    margin-bottom: 10px;
  }

  .storybook-accessibility .resources-left {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .storybook-accessibility .resources .resources-left--inner {
    height: 585px;
  }

  .storybook-accessibility .resources-right--inner {
      height: 555px;
  }
}
