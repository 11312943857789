.file-upload {
  margin: auto;
  max-width: 600px;
  width: 100%;
}

.file-upload h2 {
  line-height: 40px;
  white-space: pre-line;
  white-space: pre-wrap;
}

.file-upload .storybook-button {
  border-radius: 4px;
  display: block;
  margin: 15px auto 0;
  width: 120px;
}

.file-upload .storybook-button:disabled {
  border-color: var(--button-bg-disabled);
}

.file-dropzone {
  align-items: center;
  background-color: var(--grey-brightest);
  border: 1px dashed var(--grey-light);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 30px;
  position: relative;
  flex-direction: column;
  transition: all .2s ease-in-out;
}

.file-dropzone.over {
  border-color: var(--red);
  box-shadow: 0px 0px 4px 0px rgba(85,85,85, .5);
}

.file-icon {
  transition: all .2s ease-in-out;
  transform: scale(1);
}

.file-dropzone.over .file-icon {
  transform: scale(1.1);
}

.file-dropzone p {
  color: var(--grey-dark);
  line-height: 1.2;
}

.file-upload b {
  color: var(--red);
}

.file-drag-here {
  margin-top: 10px;
}

.file-name {
  margin-top: 10px;
}

.file-delete {
  cursor: pointer;
  padding: 0;
  border-width: 0;
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
}

.file-delete svg {
  font-size: 1.4rem;
  fill: #777777;
}

@media (min-width: 768px) {
  .file-dropzone {
    min-height: 200px;
    margin-bottom: 20px;
  }

  .file-upload .storybook-button {
    margin: 25px auto 0;
  }

  .file-name {
    margin-top: 30px;
  }

  .file-drag-here {
    margin-top: 20px;
  }

}

@media (max-width: 767px) {
  .file-upload h2 {
    font-size: var(--font-size-16);
    line-height: var(--line-spacing-24);
  }

  .file-upload .storybook-notice,
  .file-dropzone p {
    font-size: var(--font-size-12);
  }

  .file-icon {
    width: 72px;
  }

  .file-drag-here span,
  .file-drag-here strong {
    display: none;
  }
}
