.storybook-search-container {
  position: relative;
  display: flex;
  gap: 5px;
  border: 2px solid var(--white-pure);
  border-radius: 20px;
  background-color: var(--white-pure); 
  margin-left: auto;
  max-width: 100%;
  max-height: 40px;
  padding: 0;
}

.storybook-search-input {
  flex-grow: 1;
  order: 1;
  border: none;
  outline: none;
  border-radius: 20px;
  color: var(--grey-darker);
  font-size: var(--font-size-14);
  font-family: var(--font-family-lato);
  width: 100%;
  padding: 8px 14px 10px;
  height: 36px;
}

.storybook-search-icon {
  order: 2;
  cursor: pointer;
  padding: 6px 16px 6px 8px;
  box-sizing: content-box;
}

.storybook-search-container .storybook-search-icon {
  fill: var(--grey-dark);
}

.storybook-search-container:focus-within {
  border-color: var(--red);
}

.storybook-search-container:focus-within .storybook-search-icon {
  fill: var(--red);
}

.storybook-search-recent-container {
  position: absolute;
  background-color: var(--white-pure);
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
  top: calc(100% + 10px);
  width: 100%;
}

.storybook-search-recent-header {
  color: var(--grey-light);
  font-size: var(--font-size-14);
  font-weight: bold;
  padding: 11px 20px;
  border-bottom: 1px solid #eeeeee;
}

.storybook-search-recent-item {
  padding: 11px 20px;
  border-bottom: 1px solid var(--button-bg-disabled);
  cursor: pointer;
  font-size: var(--font-size-14);
  font-weight: bold;
  color: var(--grey-darker);
}

.storybook-search-recent-item:last-child {
  border-bottom-color: var(--grey-disabled);
}

.storybook-search-recent-item:hover {
  background-color: var(--button-bg-disabled);
  color: var(--red);
}

@media (max-width: 1080px) {
  .storybook-search-container {
    gap: 3px;
  }
  .storybook-search-input {
    font-size: var(--font-size-12);
  }
}
