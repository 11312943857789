.storybook-list-item {
  list-style: none;
}

.storybook-list-item a.active,
.storybook-list-item span.active,
.storybook-list-item a:hover,
.storybook-list-item span:hover
{
  color: var(--blue);
}

.storybook-list-item span {
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.storybook-list--dropdown li.storybook-list-item__extra {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

li.storybook-list-item__extra .react-switch-handle {
  border: 1px solid var(--grey-lighter)!important;
  left: 0px;
  top: 1px!important;
}

.storybook-list-item__extra b {
  color: var(--grey-darker);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-18);
}

.storybook-list-item__extra p {
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-20);
  margin: 0;
}
