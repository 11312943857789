.career-services .wellness {
  padding-bottom: 0;
}

.career-services .wellness button {
  max-width: 220px;
}

.career-services .accordion button h4 {
  text-align: left;
}

@media (min-width: 768px) {
  .careers-row {
    display: flex;
  }

  .careers-row .career-sidebar {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .career-services .card,
  .career-services .career-sidebar {
    margin-bottom: 10px;
  }
}
