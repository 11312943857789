.center {
  margin: 0 auto;
}
.margin-bottom-override {
  margin-bottom: 1rem !important;
}

.height-100 {
  height: 100% !important;
}

.height-50 {
  height: 50% !important;
}

.height-1x {
  height: 1rem !important;
}

.height-2x {
  height: 2em !important;
}

.width-100 {
  width: 97% !important;
}

.width-75 {
  width: 75% !important;
}

.width-40 {
  width: 40% !important;
}

.skeleton-container .storybook-dashboard-myapps {
  border-top: 1px solid #e6e6e6;
  border-width: 80%;
}

@media (max-width: 480px) {
  .storybook-dashboard .skeleton-container {
    display: flex !important;
    flex-direction: column;
    width: auto !important;
    padding-inline: 10px;
  }
  .storybook-dashboard-message .skeleton:nth-child(1) {
    margin-bottom: 5px;
  }
  .storybook-dashboard-message .skeleton:nth-child(2),
  .storybook-dashboard-message .skeleton:nth-child(3) {
    margin-block: 5px;
  }

  .storybook-dashboard-rightcolumn .storybook-dashboard-message {
    display: none;
  }
  /* .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container,
  .storybook-app-badge {
    display: grid;
    grid-template-columns: 40px auto;
    gap: 5px 10px;
    padding-inline: 1rem;
  } */
  .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container
    .skeleton.profile-rectangle,
  .storybook-app-badge .skeleton.profile-rectangle--small {
    grid-column-start: 1;
    grid-row: 1 / 6;
  }
  .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container
    .skeleton.text,
  .storybook-app-badge .skeleton.text {
    width: 60% !important;
  }
  .storybook-app-badge .skeleton.text {
    width: 200px !important;
    margin: auto 20%;
  }
  .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container
    .skeleton.title,
  .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container
    .skeleton.text:nth-child(3),
  .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container
    .skeleton.text:nth-child(6),
  .storybook-app-badge .skeleton.profile-renctangle--small {
    width: 40% !important;
    margin: auto 20%;
    margin-bottom: 5px;
  }
  .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container
    .skeleton.title,
  .storybook-dashboard-leftcolumn
    .storybook-dashboard-usercard
    .storybook-card-container
    .skeleton.text,
  .storybook-app-badge .skeleton.text {
    grid-column-start: 2;
    margin-top: 0;
    margin-bottom: 0.25rem;
  }
}
