.storybook-header {
  background-color: var(--white-pure);
  box-shadow: 0px 3px 6px var(--grey-lighter);
}

.storybook-header-inner {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  gap: 10px;
  max-height: 77px;
}

.storybook-header--loggedout .storybook-icon-container {
  margin-left: auto;
}

.storybook-logo {
  margin-right: 60px;
}

.storybook-logo img {
  max-width: 68px;
}

.storybook-header button + button {
  margin-left: 10px;
}

.storybook-header .welcome {
  color: #333;
  font-size: 14px;
  margin-right: 10px;
}

.storybook-menu-container  {
  margin-right: auto;
}

/********************************** 
* Icon Container
**********************************/
.icon-help {
  background: url('../assets/icon-help.svg') center no-repeat;
  display: block;
  height: 14px;
  width: 8px;
}

.theme-cold .icon-help {
  background: url('../assets/icon-help-blue.svg') center no-repeat;
}

.storybook-icon-container {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
}

.storybook-icon-help {
  align-self: center;
  display: flex;
  border: 1px solid var(--grey-light);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.storybook-icon-help.active,
.storybook-icon-help:hover {
  border-color: var(--red);
}

.storybook-icon-help.active .icon-help,
.storybook-icon-help:hover .icon-help {
  background: url('../assets/icon-help-red.svg') center no-repeat;
}

.theme-cold .storybook-icon-help.active .icon-help,
.theme-cold .storybook-icon-help:hover .icon-help {
  background: url('../assets/icon-help-blue.svg') center no-repeat;
}

.storybook-icon-container .icon-help img {
  fill: var(--grey-light);
  height: 14px;
  width: 8px;
}

.storybook-icon-container svg {
  outline: 1px solid var(--grey-lighter);
  outline-offset: 5px;
  border-radius: 50%;
  margin: 0 0.25em;
  color: var(--icon-color);
  flex-grow: 2;
  align-self: center;
  font-size: 1.3em;
}

#profilemenuContainer .storybook-list--dropdown {
  top: 60px;
  left: -55px;
}
#profilemenuContainer .storybook-list--dropdown li a {
  display: flex;
  padding-inline: 15px;
  min-width: 206px;
}

/********************************** 
* Tablet View 
***********************************/

@media (max-width: 834px) {
  .storybook-logo {
    margin-right: 10px;  
  }
  .storybook-icon-container svg {
    font-size: 1em;
  }
  .storybook-avatar--medium {
    height: 30px;
    width: 30px;
    font-size: var(--font-size-10);
  }
}

@media (min-width: 768px) {
  .storybook-logo img {
    max-width: 105px;
  }
}

@media (max-width: 767px) {
  /* Header */
  .storybook-header {
    gap: 5%;
  }
  .storybook-menu-container {
    order: 1;
  }
  .storybook-logo {
    order: 2;
    flex-grow: 2;
  } 
  .storybook-search-container {
    order: 3;
    margin-left: auto;
  } 
  .storybook-icon-container {
    order: 4;
  }
  
  #profilemenuContainer .storybook-list--dropdown.open {    
    left: -25px;
    max-width: 120px;
    margin: unset;
    padding-top: 10px;
  }
}

/********************************** 
* Mobile View 
***********************************/

@media (max-width: 320px) {
  .storybook-header {
    height: 60px;
  }
  .storybook-logo img {
    max-width: 69px;
  }
  .storybook-icon-container svg {
    font-size: 1em;
  }
  .storybook-avatar--medium {
    height: 24px;
    width: 24px;
    font-size: var(--font-size-10);
  }
  .storybook-menu-toggle svg {
    font-size: 1.7rem;
  }
}