.storybook-alert {
  margin: 10px auto;
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
}

.storybook-alert > * {
  padding-inline: 28px;
}

.storybook-alert > header {
  padding-top: 16px;
}

.storybook-alert > *:last-child {
  padding-bottom: 16px;
}

.storybook-alert--warning {
  border-color: #ccbea0;
  background-color: #fffaf3;
  color: var(--warning);
}

.storybook-alert--info {
  border-color: var(--info-border);
  background-color: var(--info-background);
  color: var(--info);
}

.storybook-alert--success {
  border-color: var(--success-border);
  background-color: var(--success-background);
  color: var(--success);
}

.storybook-alert--error {
  border-color: var(--error-border);
  background-color: var(--error-background);
  color: var(--error);
}

.storybook-alert header {
  font-size: var(--font-size-18);
}

.storybook-alert p {
  font-size: var(--font-size-14);
  padding-block: 10px;
  margin-bottom: 0;
}

.storybook-alert-close {
  border: 0;
  background-color: transparent;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  padding: 0;
}
.storybook-alert-close svg {
  font-size: var(--font-size-14);
}

.storybook-alert-close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.storybook-alert--info .storybook-alert-close svg {
  fill: var(--info);
}
.storybook-alert--success .storybook-alert-close svg {
  fill: var(--success);
}
.storybook-alert--warning .storybook-alert-close svg {
  fill: var(--warning);
}
.storybook-alert--error .storybook-alert-close svg {
  fill: var(--error);
}
