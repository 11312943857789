/* These aren't the best solution for customizing joyride elements
   but some elements for specific steps are hard to customize.
*/
.react-joyride__overlay {
  min-height: 100%;
}

#react-joyride-step-0 + #react-joyride-portal .react-joyride__overlay .react-joyride__spotlight {
  box-sizing: content-box;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
}

#react-joyride-step-0 .__floater {
  margin-top: 10px;
}

.joyride-step-5 .react-joyride__overlay .react-joyride__spotlight {
  border-radius: 50%!important;
  max-width: 31px;
  max-height: 31px;
  margin-top: 1px;
}

.joyride-step-4.joyride-step-no-milestone .react-joyride__overlay .react-joyride__spotlight {
  box-sizing: content-box;
  margin: -20px -20px 0 -20px;
  padding: 20px 20px 0 20px;
}

.joyride-step-1 .react-joyride__overlay .react-joyride__spotlight {
  max-height: 72px;
  margin-top: 2px;
}

@media screen and (min-width: 768px) {
  #react-joyride-step-0 + #react-joyride-portal .react-joyride__overlay .react-joyride__spotlight {
    padding: 20px;
    margin-left: -20px;
    margin-top: -20px;
  }

  #react-joyride-step-0 .__floater__body,
  #react-joyride-step-0 .__floater__arrow {
    margin-top: 20px;
  }

  .joyride-step-1 .react-joyride__overlay .react-joyride__spotlight {
    max-height: 72px;
    margin-top: 2px;
  }

  .joyride-step-4.joyride-step-no-milestone .react-joyride__overlay .react-joyride__spotlight {
    padding: 20px;
    margin: -20px;
  }
}

@media screen and (max-width: 767px) {
  #react-joyride-step-0 + #react-joyride-portal .react-joyride__overlay .react-joyride__spotlight {
    border-radius: 0!important;
  }

  .joyride-step-1 .react-joyride__overlay .react-joyride__spotlight {
    border-radius: 0!important;
    max-height: 30px;
    min-height: 30px;
    margin-top: 23px;
    margin-left: -3px;
    min-width: 30px;
    max-width: 30px;
  }

  #react-joyride-step-1 .__floater {
    margin-top: -22px;
  }
}