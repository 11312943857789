.career-sidebar {
  background: var(--white-pure);
  border-radius: 8px;
  box-sizing: border-box;
  height: fit-content;
  flex-shrink: 0;
  padding: 30px 30px 50px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.career-sidebar > p {
  color: var(--grey);
}

.career-sidebar hr {
  border-top-color: var(--grey-disabled);
  border-width: 1px 0 0 0;
  border-style: solid;
  margin: 20px 0;
}

.career-sidebar h4 {
  color: var(--grey-dark);
}

.career-sidebar h3 {
  color: var(--blue-purple);
  margin-top: 0;
}

.career-workshops {
  margin-bottom: 45px;
}

.career-workshops + a {
  color: var(--red);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-24);
}

.career-workshops + b {
  color: var(--grey-light);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-24);
}

.career-workshops__item {
  display: block;
  line-height: 1;
  align-items: center;
  display: flex;
  font-size: var(--font-size-12);
  text-align: left;
  margin-bottom: 20px;
}

p.career-workshops__item.active {
  color: var(--grey-darker);
}

p.career-workshops__item {
  color: var(--grey-light);
}

.career-workshops__item .career-color {
  border-radius: 50%;
  background-color: var(--grey-lighter);
  display: block;
  flex-shrink: 0;
  margin-right: 10px;
  height: 16px;
  width: 16px;
}

.career-color.red {
  background-color: var(--career-red);
}

.career-color.yellow {
  background-color: var(--career-yellow);
}

.career-color.blue {
  background-color: var(--career-blue);
}

.career-color.green {
  background-color: var(--career-green);
}

@media (min-width: 768px) {
  .career-sidebar {
    max-width: 295px;
    margin-bottom: 40px;
  }

  .career-sidebar hr {
    margin: 25px 0;
  }

  .career-workshops__item {
    font-size: var(--font-size-14);
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .career-sidebar > p {
    font-size: var(--font-size-12);
  }

  .career-sidebar h3 {
    font-size: var(--font-size-16);
    margin-bottom: 0;
  }

  .career-sidebar h4 {
    font-size: var(--font-size-12);
  }

  .career-workshops {
    max-width: 220px;
    margin: 0 auto 20px;
  }
}