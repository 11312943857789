.storybook-menu-container {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  height: 77px;
}

.storybook-menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: var(--grey-dark);
}

.storybook-menu,
.storybook-menu-container .storybook-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: inherit;
  display: flex;
  align-items: center;
  background-color: var(--white-pure);
  height: 100%;
}

.storybook-menu li,
.storybook-menu-container .storybook-list li {
  display: flex;
  margin: 0;
  padding: 0;
  height: 100%;
}

.storybook-menu li a,
.storybook-menu-container .storybook-list li a,
.storybook-menu-container .storybook-list li span,
.storybook-menu li span {
  text-decoration: none;
  color: var(--grey-dark);
  padding: 0px 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px transparent;
  white-space: nowrap;
  font-size: 1em;
  height: 100%;
}

.storybook-menu-container
  > .storybook-list
  li.storybook-list-item--with-dropdown
  span {
  padding: 0px 15px 0px 20px;
}

.storybook-menu-container .storybook-list > li > a {
  position: relative;
}

.storybook-menu-container .storybook-list > li > span:after,
.storybook-menu-container .storybook-list > li > a:after {
  background-color: var(--red);
  border-radius: 5px;
  content: "";
  display: block;
  position: absolute;
  height: 0;
  left: 20px;
  right: 20px;
  bottom: 15px;
}

/* .storybook-menu li a.active,
.storybook-menu li a:hover, */
.storybook-menu-container .storybook-list li a.active,
.storybook-menu-container .storybook-list li a:hover,
.storybook-menu-container .storybook-list li span:hover,
.storybook-menu-container .storybook-list li span.active {
  color: var(--red);
}

.storybook-menu-container .storybook-list li a.active:after,
.storybook-menu-container .storybook-list li a:hover:after,
.storybook-menu-container .storybook-list li span:hover:after,
.storybook-menu-container .storybook-list li span.active:after {
  height: 4px;
}

.storybook-menu-container .storybook-list li span {
  cursor: pointer;
}

.storybook-menu li a:hover,
.storybook-menu-container .storybook-list li a:hover,
.storybook-menu-container .storybook-list li span:hover {
  background-color: #f4f4f4;
  color: var(--red);
  fill: var(--red);
}

.storybook-menu-container .storybook-list li span svg {
  margin-top: 5px;
  fill: inherit;
}

.storybook-menu-container .storybook-list li span.active svg {
  fill: var(--red);
}

/* Side Nav */

.storybook-sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: -100vw;
  background-color: var(--white-pure);
  transition: 0.5s;
  overflow-y: scroll;
  width: 0;
  left: -100vw;
}

.storybook-sidenav.open {
  width: 100vw;
  left: 0;
}

.storybook-sidenav header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin: 18px 20px;
}
.storybook-sidenav-close {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

button.storybook-sidenav-close svg,
.storybook-list-item--with-dropdown span svg {
  font-size: 1.3rem;
}
.storybook-sidenav header .storybook-logo {
  display: flex;
  justify-content: center;
  margin-right: unset;
}
.storybook-sidenav header img {
  max-height: 24px;
}

.storybook-sidenav .storybook-list {
  flex-direction: column;
}

.storybook-menu-container .storybook-sidenav .storybook-list li {
  height: auto;
  margin: 0;
}

.storybook-sidenav .storybook-list li a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

@media (max-width: 834px) {
  .storybook-menu li a,
  .storybook-menu-container .storybook-list li a,
  .storybook-menu-container .storybook-list li span,
  .storybook-menu li span {
    padding: 0px 15px;
  }
}
@media (max-width: 768px) {
  .storybook-menu li,
  .storybook-menu-container .storybook-list li {
    display: block;
    margin: 10px 0;
  }

  .storybook-menu,
  .storybook-menu-container > .storybook-list {
    display: none;
  }

  .storybook-menu-toggle {
    display: flex;
    align-items: center;
  }

  .storybook-menu-container[data-testid="profilemenu-container"]
    .storybook-menu-toggle {
    display: none;
  }

  .mobile-menu {
    right: 0;
  }
  /* Side nav */

  .storybook-menu-container
    .storybook-sidenav
    .storybook-list
    .storybook-list--dropdown
    li
    a {
    padding: 0px 65px;
  }

  .storybook-sidenav .storybook-list li,
  .storybook-sidenav .storybook-list li a {
    width: 100%;
    padding: 0 20px;
  }
  .storybook-sidenav .storybook-list li a,
  .storybook-sidenav .storybook-list li span {
    height: 60px;
    display: flex;
  }
  .storybook-sidenav .storybook-list li a,
  .storybook-sidenav .storybook-list li span {
    width: auto;
    padding-bottom: 0.45em;
    font-size: var(--font-size-16);
    padding-left: 45px;
    color: var(--grey-dark);
    min-height: 2rem;
  }
  .storybook-sidenav .storybook-list li a.active,
  .storybook-sidenav .storybook-list li a:hover,
  .storybook-sidenav .storybook-list li span:hover {
    text-decoration: none;
  }
  .storybook-sidenav .storybook-list li span {
    justify-content: flex-end;
  }

  .storybook-sidenav .storybook-list li span svg {
    margin-left: auto;
    padding-right: 2em;
  }
  .storybook-sidenav .storybook-list--dropdown.open {
    position: inherit;
    margin-left: unset;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0%;
    top: 0;
    left: 0;
  }
  .storybook-sidenav .storybook-list--dropdown.open li {
    height: 60px;
    border-radius: 0%;
  }
  .storybook-sidenav .storybook-list--dropdown.open li a,
  .storybook-list--dropdown.open li a {
    padding-top: 0 !important;
    padding-left: 40px;
  }
  .storybook-list--dropdown.open,
  .storybook-list--dropdown.open li {
    padding: 0;
  }
  .storybook-list--dropdown.open .storybook-list-item a:hover {
    text-decoration: none;
    background-color: inherit;
    color: var(--red);
    background-color: #f4f4f4;
  }
}

@media screen and (max-height: 450px) {
  .storybook-sidenav {
    padding-top: 15px;
  }
  .storybook-sidenav a {
    font-size: 18px;
  }
}
@media screen and (max-width: 428px) {
  .storybook-sidenav .storybook-list li {
    padding: 0;
  }
}
