body {
  background-color: var(--cream-light);
}

article > header {
  position: relative;
}

.storybook-sidenav {
  z-index: 99;
}

.storybook-dashboard {
  padding-bottom: 20px;
  height: 100%;
}

.storybook-dashboard-rightcolumn {
  grid-area: rightcol;
  flex: 1;
  width: 100%;
}

.storybook-dashboard-message {
  display: flex;
  flex-direction: column;
  /* grid-area: msg; */
}

.storybook-dashboard-leftcolumn {
  grid-area: leftcol;
}

.storybook-dashboard-inner,
.storybook-dashboard .skeleton-container {
  display: flex;
  /* grid-template-areas:
    "leftcol msg"
    "leftcol rightcol";
  grid-template-columns: 23.5% auto;
  grid-template-rows: 100px 1fr; */
  gap: 20px;
}

.storybook-dashboard .skeleton-container {
  max-width: 1230px;
  margin: 0 auto;
  grid-template-areas:
    "leftcol msg"
    "leftcol rightcol";
}

.storybook-dashboard-leftcolumn {
  max-width: 291px;
  background-color: var(--white-pure);
  border: transparent;
  border-radius: 0 0 8px 8px;
  padding: 1em 0;
  width: 100%;
}

.storybook-dashboard-leftcolumn .storybook-myapps-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.storybook-myapps-container .storybook-app-badge {
  margin-bottom: 1.2em;
}
.storybook-myapps-container .storybook-app-badge--copy {
  margin-bottom: 14px;
}

.storybook-app-badge[disabled]
  .storybook-app-badge--copy
  span
  + span.hover-tip {
  display: none;
  width: 185px;
  line-height: 15px;
}
.storybook-app-badge[disabled]
  .storybook-app-badge--copy:hover
  span
  + span.hover-tip,
.storybook-app-badge[disabled]:hover
  img:hover
  + .storybook-app-badge--copy
  span.hover-tip {
  display: block;
  top: -30px;
  left: 48%;
  text-wrap: wrap;
}

/* Hide hover tip "action needed here!" for now  */
.storybook-task-item--started .item-icon span.hover-tip {
  display: none !important;
}

.storybook-dashboard-message h1 {
  font-size: var(--font-size-24);
}

.storybook-dashboard-message p {
  font-size: var(--font-size-14);
  margin-bottom: 0;
}

.storybook-dashboard-leftcolumn h2,
.storybook-dashboard-rightcolumn h2 {
  color: var(--blue-purple);
  font-size: var(--font-size-16);
}

.storybook-dashboard-rightcolumn h2 {
  margin-top: 0;
}

.storybook-dashboard-leftcolumn h2 {
  margin-top: 1em;
}

.storybook-dashboard-leftcolumn h2 {
  text-align: center;
}

.storybook-dashboard-leftcolumn .storybook-popover-content {
  padding: 6px;
  text-align: left;
  width: 220px;
}

h2.storybook-card-title {
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-30);
  font-weight: var(--font-weight-bold);
  color: var(--grey-darker) !important;
}

.storybook-dashboard-needhelp.white-container h2 {
  text-align: inherit;
}

.storybook-dashboard-rightcolumn h3 {
  font-size: var(--font-size-14);
}

.storybook-dashboard .storybook-card-container p {
  font-size: var(--font-size-12);
  color: var(--grey);
}
.storybook-dashboard-rightcolumn p {
  font-size: var(--font-size-14);
}

.white-container {
  background: var(--white-pure) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 20px;
}

.box-shadow {
  box-shadow: 0px 3px 6px var(--grey-lighter);
}

.storybook-dashboard-mytasks {
  overflow-x: hidden;
  margin: 0 0 20px;
}

.storybook-dashboard-needhelp {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  justify-content: space-between;
  min-height: 230px;
}

.storybook-dashboard-needhelp > .storybook-app-badge {
  flex-basis: 15.5%;
}

.storybook-dashboard-needhelp h2 {
  flex-basis: 100%;
}

.storybook-dashboard-needhelp .storybook-app-badge img {
  width: 54px;
  height: 54px;
}

.storybook-dashboard-overall-progress header,
.storybook-dashboard-mytasks header {
  display: flex;
}

.storybook-dashboard-overall-progress header {
  align-items: baseline;
}

.storybook-dashboard-mytasks header {
  align-items: center;
  margin-bottom: 0.1em;
}
.storybook-dashboard-mytasks header h2 {
  margin: 0;
}

.storybook-dashboard-mytasks p {
  margin-top: 0;
}

.storybook-dashboard-overall-progress header p,
.storybook-dashboard-mytasks header button {
  margin-right: auto;
  margin-left: 1em;
  font-size: var(--font-size-12);
}

.storybook-dashboard-overall-progress header svg,
.storybook-dashboard-mytasks header svg {
  fill: var(--grey-light);
  font-size: 1.15em;
  cursor: pointer;
}

.storybook-dashboard-overall-progress header svg:hover,
.storybook-dashboard-mytasks header svg:hover {
  fill: var(--red);
}

.storybook-task-progress-wrapper {
  margin-top: 20px;
}

.storybook-modal-header {
  min-height: 2em;
}

hr {
  border: none;
  border-top: 1px double var(--grey-lighter);
}

.center {
  text-align: center;
}

.storybook-dashboard-myapps-show-more {
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0.5em;
}

.storybook-dashboard-myapps-show-more .expand-rotate {
  transform: rotate(180deg);
}

/* Coursework dashlet */
.storybook-dashboard-mytasks.coursework h3 {
  margin-top: 0;
  color: var(--blue-purple);
  font-size: var(--font-size-13);
}

.storybook-dashboard-mytasks.coursework .storybook-progressbar {
  margin: 1.2em 0.95em;
}

.storybook-dashboard-mytasks.coursework .storybook-progressbar-label,
.storybook-dashboard-mytasks.coursework .storybook-progressbar-desc {
  font-size: var(--font-size-12);
  color: var(--grey);
  font-weight: 400;
  margin-bottom: 1.25em;
  width: auto;
}
.storybook-dashboard-mytasks.coursework a .storybook-progressbar-label:hover,
.storybook-dashboard-mytasks.coursework a .storybook-progressbar-desc:hover {
  color: var(--red);
  text-decoration: underline;
}

.storybook-dashboard-mytasks.coursework .storybook-progressbar-desc::before {
  content: " - ";
  padding-right: 3px;
}

.storybook-dashboard-mytasks.coursework .storybook-progressbar-progress span {
  left: 35px;
}

.storybook-dashboard-mytasks.coursework [class^="scale-"] {
  display: none;
}

.storybook-dashboard-mytasks.coursework button {
  display: block;
  margin: 0 auto;
  margin-top: 4em;
}

.storybook-btn-link {
  border: 0;
  background-color: transparent;
  font-size: var(--font-size-12);
  font-family: var(--font-family-lato);
  cursor: pointer;
}

.storybook-btn-link:hover {
  color: var(--red);
}

.storybook-dashboard-mytasks.coursework footer {
  margin: 0 auto;
  margin: 2em 0 1.15em 0em;
}

.storybook-dashboard-mytasks.coursework footer .storybook-button,
.storybook-dashboard-mytasks.coursework footer p.storybook-mytasks-info {
  align-content: center;
  margin: 0 auto;
}

.storybook-dashboard-mytasks.coursework footer p.storybook-mytasks-info {
  font-size: 0.68rem;
  color: var(--grey-light);
  float: right;
  margin-top: -30px;
  margin-right: 20px;
}

.storybook-dashboard-mytasks.coursework footer p.storybook-mytasks-info svg {
  fill: var(--grey-light);
  font-size: var(--font-size-12);
  line-height: 2px;
  margin-bottom: -1px;
}

#react-joyride-step-5 button {
  width: 100%;
}

.__floater__body button:focus,
.__floater__body button:active,
.__floater__body button:focus-visible {
  outline: none;
}

.storybook-task-item--in-progress {
  cursor: unset;
}
.storybook-task-item--in-progress:nth-child(1),
.storybook-task-item--in-progress:nth-child(2),
.storybook-task-item--in-progress:nth-child(3) {
  cursor: pointer;
}
.storybook-task-item--in-progress:nth-child(1):hover,
.storybook-task-item--in-progress:nth-child(2):hover,
.storybook-task-item--in-progress:nth-child(3):hover {
  text-decoration: underline;
  text-decoration-color: var(--red);
  -webkit-text-decoration-color: var(--red);
  text-underline-offset: 0.25em;
}

.dockableContainer * {
  font-family: var(--font-family-lato);
  font-weight: var(--font-weight-bold);
}

.embeddedServiceHelpButton .helpButtonEnabled:focus .helpButtonLabel {
  text-decoration: none;
}

.modalContainer .embeddedServiceSidebarMinimizedDefaultUI.helpButton,
.embeddedServiceHelpButton .helpButton button.uiButton,
.embeddedServiceSidebarMinimizedDefaultUI.helpButton,
.dockableContainer .chatHeaderBranding {
  background-color: var(--chat-head, #ffe3b7);
  box-shadow: none;
  border-width: 0;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
}

.modalContainer .embeddedServiceSidebarMinimizedDefaultUI.helpButton .message,
.embeddedServiceHelpButton button.uiButton .helpButtonLabel .message {
  color: var(--chat-text-head);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-16);
}

.embeddedServiceHelpButton .uiButton .embeddedServiceIcon::before {
  color: var(--chat-text-head);
  font-size: var(--font-size-18);
}

.modalContainer
  .embeddedServiceSidebarMinimizedDefaultUI.helpButton
  .embeddedServiceIcon
  svg {
  fill: var(--chat-text-head);
  height: 18px;
  width: 18px;
}

.embeddedServiceHelpButton .helpButton button.helpButtonEnabled::before,
.embeddedServiceHelpButton .helpButton button.helpButtonEnabled::before {
  transition: all 0.2s ease-in-out;
}

.modalContainer
  .embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus,
.modalContainer
  .embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:hover,
.embeddedServiceHelpButton .helpButton button.helpButtonEnabled:hover::before,
.embeddedServiceHelpButton .helpButton button.helpButtonEnabled:focus::before {
  background-color: var(--chat-head-hover, #ffd280);
  opacity: 1;
  text-decoration: none;
}

.dockableContainer embeddedservice-chat-header lightning-icon,
.dockableContainer embeddedservice-chat-header svg.slds-icon-text-default {
  fill: var(--chat-text-head);
}

.dockableContainer h2[lwc-56vbshdpqil] {
  color: var(--chat-text-head);
  font-weight: var(--font-weight-bold);
}

.dockableContainer .embeddedServiceSidebarButton {
  background-color: var(--red);
}

.dockableContainer .embeddedServiceSidebarButton:not(:disabled):focus,
.dockableContainer .embeddedServiceSidebarButton:not(:disabled):hover {
  background-color: var(--red-dark);
}

.dockableContainer .embeddedServiceSidebarFormField .slds-style-inputtext,
.dockableContainer .embeddedServiceSidebarFormField .slds-style-select {
  border-radius: 6px;
  height: 38px;
}

.dockableContainer .embeddedServiceSidebarFormField .uiInput .uiLabel-left {
  color: var(--grey-dark);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-style: normal;
  margin-bottom: 4px;
}

.dockableContainer .embeddedServiceSidebarFormField .uiInput .required {
  top: -2px;
}

.dockableContainer .embeddedServiceSidebarFormField .split-field-container,
.dockableContainer .embeddedServiceSidebarFormField .uiInput--input,
.dockableContainer .embeddedServiceSidebarFormField .uiInput--select {
  margin-bottom: 15px;
}

.dockableContainer .embeddedServiceSidebarFormField .slds-style-inputtext:focus,
.dockableContainer .embeddedServiceSidebarFormField .slds-style-select:focus {
  box-shadow: none;
  border-color: var(--red);
}

.dockableContainer .embeddedServiceSidebarFormField .uiInputSelect:after {
  border-top-color: var(--red);
}

.embeddedServiceSidebar.layout-docked .dockableContainer,
.embeddedServiceSidebar.layout-float .dockableContainer {
  box-shadow: none;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
}

.storybook-task-item--in-progress .item-pending {
  width: 200px;
  top: -38px;
}

@media (max-width: 1200px) {
  .storybook-dashboard-mytasks.coursework footer {
    display: flex;
    flex-wrap: wrap;

    gap: 1em;
  }
  .storybook-dashboard-mytasks.coursework footer p.storybook-mytasks-info {
    font-size: var(--font-size-12);
    color: var(--grey-light);
    float: unset;
    margin-top: 10px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .storybook-dashboard-message.no-desktop {
    display: none;
  }

  .storybook-dashboard-needhelp .storybook-app-badge--copy {
    line-height: var(--line-spacing-20);
    width: 75px;
  }

  .storybook-dashboard-needhelp
    .storybook-app-badge:nth-child(3)
    .storybook-app-badge--copy {
    width: 70px;
  }

  .storybook-dashboard-needhelp .storybook-app-badge--copy {
    line-height: var(--line-spacing-20);
    width: 75px;
  }

  .storybook-dashboard-needhelp
    .storybook-app-badge:nth-child(3)
    .storybook-app-badge--copy {
    width: 45px;
  }
}

@media (max-width: 767px) {
  .storybook-dashboard-inner {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .storybook-dashboard-leftcolumn {
    border-radius: 8px;
  }

  .storybook-dashboard-rightcolumn,
  .storybook-welcome-message,
  .storybook-dashboard-leftcolumn {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .storybook-dashboard-message.no-mobile {
    display: none;
  }

  .storybook-dashboard .storybook-card-container p svg {
    margin-top: unset;
  }

  .storybook-welcome-message {
    padding: 0 10px;
  }

  .storybook-dashboard .storybook-card-content p:not(p:first-of-type) {
    font-size: var(--font-size-10);
  }
  p,
  span,
  .storybook-progressbar-desc,
  .storybook-dashboard-mytasks p {
    font-size: var(--font-size-12);
  }
  .storybook-dashboard .storybook-card-content .storybook-popover-trigger svg {
    padding: 0.5em;
    font-size: var(--font-size-12);
  }

  .storybook-dashboard-mytasks {
    width: revert;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
  }

  .storybook-dashboard-mytasks > header {
    order: 1;
  }

  .storybook-dashboard-mytasks > p {
    order: 2;
    color: var(--grey-dark);
  }
  .storybook-dashboard-mytasks > h3 {
    order: 2;
  }

  .storybook-dashboard-mytasks > div.center {
    order: 3;
    text-align: unset;
    margin-bottom: 1em;
  }
  .storybook-dashboard-mytasks.coursework button,
  .storybook-dashboard-mytasks.coursework footer {
    margin: 0 0 0 0;
  }
  .storybook-dashboard-mytasks.coursework .storybook-progressbar {
    order: 4;
  }
  .storybook-dashboard-mytasks.coursework
    .storybook-progressbar-label
    .storybook-dashboard-mytasks.coursework
    .storybook-progressbar-desc {
    width: auto;
  }

  .storybook-dashboard-mytasks .storybook-task-progress-wrapper {
    order: 4;
  }
  .storybook-task-progress-desc {
    order: 5;
  }
  .storybook-task-progress-desc > h2 {
    text-align: left !important;
  }

  .storybook-dashboard-overall-progress .storybook-progressbar {
    display: flex;
    flex-direction: column;
  }

  .storybook-dashboard-overall-progress .storybook-progressbar-label {
    order: 1;
    font-weight: var(--font-weight-normal);
    color: var(--grey-dark);
  }
  .storybook-dashboard-overall-progress .storybook-progressbar-bckgrnd-bar {
    order: 2;
  }
  .storybook-dashboard-overall-progress .storybook-progressbar-desc {
    order: 3;
    justify-content: left;
    padding-top: 0.5em;
    color: var(--grey-dark);
  }

  .storybook-task-item .item-title {
    display: flex;
  }

  .storybook-dashboard-mytasks .item-title p:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }

  .storybook-dashboard-myapps-show-more {
    display: flex;
    padding: 10px 0;
  }

  .storybook-welcome-message h1 {
    font-size: var(--font-size-18);
  }
  .storybook-welcome-message p {
    font-size: var(--font-size-12);
  }

  .storybook-dashboard .storybook-card-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-content: center;
    padding: 5px;
    max-width: 100%;
  }

  .storybook-dashboard .storybook-card-image {
    width: 40%;
  }

  .storybook-dashboard .storybook-card-content,
  .storybook-dashboard .storybook-card-content * {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .storybook-dashboard .storybook-card-image img {
    max-width: auto;
    margin-right: 0.5em;
  }
  .storybook-dashboard h2.storybook-card-title {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-spacing-20);
    margin-bottom: 0;
    margin-top: 0 !important;
    color: var(--grey-darker) !important;
  }
  .storybook-dashboard .storybook-card-buttons {
    justify-content: center;
    width: 100%;
    padding-left: 45px;
  }
  .storybook-dashboard-myapps {
    height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  .storybook-dashboard-myapps.show {
    height: auto;
    max-height: 100%;

    transition: max-height 0.5s ease-in-out;
  }

  .storybook-dashboard-needhelp {
    flex-direction: column;
  }

  .storybook-dashboard-needhelp * {
    flex-basis: 45%;
  }

  .storybook-dashboard-needhelp > .storybook-app-badge {
    flex-basis: content;
    flex-direction: row;
    align-items: center;
    width: 90%;
    max-width: 100%;
    justify-content: left;
    margin-left: 1em;
  }

  .storybook-dashboard-needhelp * {
    flex-basis: unset;
  }

  .storybook-dashboard-needhelp > .storybook-app-badge span {
    max-width: 50%;
  }

  .storybook-dashboard-needhelp .storybook-app-badge img {
    width: 32px;
    height: 32px;
    margin-bottom: 0;
    margin-right: 0.85em;
  }
  /* Coursework */
  .storybook-dashboard-mytasks.coursework .storybook-progressbar {
    margin: 1.2em 0em;
  }
  .storybook-dashboard-mytasks.coursework .storybook-progressbar-desc::before {
    content: "";
  }
  .storybook-dashboard-mytasks.coursework .storybook-progressbar-label,
  .storybook-dashboard-mytasks.coursework .storybook-progressbar-desc {
    width: 100%;
    justify-content: left;
    margin: auto;
    padding-bottom: 0.5em;
  }

  .storybook-dashboard-mytasks.coursework .storybook-progressbar-progress span {
    left: 35px;
    font-size: var(--font-size-12);
  }

  #react-joyride-step-1 .__floater__body {
    margin-left: 12px;
  }

  .__floater__body {
    max-width: 360px;
  }

  .storybook-myapps-container .storybook-app-badge {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 33%;
  }

  .storybook-myapps-container .storybook-app-badge img {
    margin: 0;
  }
}
