.banner-experience-survey {
  background-color: #f8ffff;
  border: 1px solid #bedfe6;
  border-radius: 8px;
  color: #0e566c;
  margin-top: 20px;
  padding: 20px;
}

.banner-experience-survey h3 {
  margin: 0 0 10px 0;
}

.banner-experience-survey p {
  margin-bottom: 0;
  line-height: var(--line-spacing-20);
}

.storybook-alert + .storybook-welcome-message .page-title {
  margin-top: 20px;
}

.storybook-dashboard-message .storybook-alert h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.storybook-dashboard-message .storybook-alert h3 + p {
  padding-top: 0;
}

.storybook-dashboard-message .storybook-alert {
  margin-bottom: 2px;
}
.storybook-dashboard-message .storybook-alert:first-of-type {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .storybook-dashboard-inner .banner-experience-survey h3 {
    font-size: var(--font-size-14);
  }

  .storybook-dashboard-inner .banner-experience-survey p {
    font-size: var(--font-size-12);
  }
}
