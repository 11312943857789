.skeleton-container .storybook-myteam-members {
  width: 1366px;
}

.skeleton-container .storybook-myteam-copy,
.skeleton-container .storybook-myteam-instructional-faculty,
.skeleton-container .storybook-myteam-peer-tutors,
.skeleton-container .storybook-myteam-academic-success-counselors {
  width: 100%;
}

@media screen and (max-width: 978px) {
  .skeleton-container .storybook-myteam-members {
    max-width: 90%;
  }
}
