.storybook-aside {
  padding: 20px;
  background-color: var(--white-pure);
}

.storybook-aside h2 {
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-24);
  color: var(--blue-purple);
  text-align: center;
}

.storybook-aside p {
  font-size: var(--font-size-12);
  color: var(--grey-dark);
  text-align: center;
}

.storybook-aside a {
  color: var(--red);
}

.storybook-aside a:hover {
  text-decoration: underline;
}

.border-radius-all {
  border-radius: 8px;
}

.border-radius-top {
  border-radius: 8px 8px 0 0;
}

.border-radius-bottom {
  border-radius: 0 0 8px 8px;
}

.box-shadow {
  box-shadow: 0px 3px 6px #00000029;
}

@media (min-width: 768px) {
  .storybook-aside {
    padding: 30px;
    max-width: 290px;
  }

  .storybook-aside h2 {
    font-size: var(--font-size-18);
    line-height: var(--line-spacing-30);
  }

  .storybook-aside p {
    font-size: var(--font-size-14);
  }
}
