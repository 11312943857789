.storybook-checkbox-container {
  display: flex;
}

.storybook-checkbox-label {
  display: none;
  font-size: var(--font-size-16);
  line-height: var(--font-size-24);
}

.storybook-checkbox-label--show {
  display: block;
}

.storybook-checkbox {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
  display: none;
}

.storybook-checkbox-container .storybook-checkbox-checked {
  color: var(--red);
  font-size: 1.5rem;
}

.storybook-checkbox-unchecked {
  color: var(--grey-light);
  accent-color: white;
  font-size: 1.5rem;
}
