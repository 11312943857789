.enroll-verification hr {
  margin-block: 5px 18px;
  margin-left: unset;
}

.enroll-verification p {
  margin-bottom: 30px;
}

.enroll-verification .field-group[data-testid="recipientEmailContainer"],
.enroll-verification .field-group[data-testid="fileUploadContainer"] {
  margin-block: 40px 20px;
}

.enroll-verification .storybook-button {
  margin-right: 10px;
  width: 100px;
}

.enroll-verification .storybook-checkbox-container {
  display: inline-block;
}

.enroll-verification .field-group #recipientEmail.storybook-input {
  display: inline-block;
  margin-block: 1em;
  margin-inline-end: 1em;
}

.enroll-verification .upload-input {
  margin: unset;
  padding-bottom: 0;
}
.enroll-verification .file-limits {
  margin-left: 0.4em;
  font-size: var(--font-size-12);
  margin-top: 5px;
}

.enroll-verification .upload-input-type-simple {
  border-color: var(--grey-lighter);
  background-color: white;
}

.enroll-verification .upload-input .storybook-button {
  margin: unset;
  font-weight: 600;
}

.enroll-verification label {
  font-size: var(--font-size-14);
  color: var(--grey-dark);
}

/* Consider move to storybook */
.enroll-verification
  .storybook-checkbox[readonly]
  + .storybook-checkbox-checked {
  color: var(--grey-lighter);
}
.enroll-verification .storybook-input:read-only:focus,
.enroll-verification .storybook-input#calbrightEmail,
.enroll-verification .storybook-input#calbrightEmail:focus,
.enroll-verification .storybook-input#personalEmail,
.enroll-verification .storybook-input#personalEmail:focus {
  border: 1px solid transparent;
  padding-left: 0;
  outline: none;
}
.enroll-verification .storybook-checkbox-checked,
.enroll-verification .storybook-checkbox-unchecked {
  align-self: center;
  position: relative;
  top: 0.25em;
  margin-right: 0.25em;
}

.recipient-email-row {
  margin-block: 0.5em;
}

.recipient-email-row input {
  display: inline-block;
}
.add-email-btn,
.remove-email-btn {
  position: relative;
  top: 8px;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-size: 10px;
}
.add-email-btn svg,
.remove-email-btn svg {
  font-size: 2.75em;
  fill: var(--grey-light);
  stroke: var(--grey-light);
  stroke-width: 2;
  font-weight: bold;
}

.add-email-btn:hover svg,
.remove-email-btn:hover svg {
  fill: var(--red-dark);
  stroke: var(--red-dark);
}

.enroll-verification button.disabled {
  opacity: 0.5;
}

.enroll-verification .error-message {
  color: var(--red-dark);
  display: block;
  position: relative;
  top: 5px;
  left: 0px;
  font-size: 12px;
  margin-bottom: unset;
}

@media (min-width: 992px) {
  .toggle .resources-left--inner {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.enroll-verification .storybook-modal-title {
  line-height: 2.5rem;
}

/** Toast **/

.enroll-verification .Toastify .Toastify__toast--success {
  background: var(--white-pure);
}
.enroll-verification .Toastify .Toastify__toast-container {
  color: var(--grey-darker);
  background: transparent;
  width: auto;
}
.enroll-verification .Toastify .Toastify__toast-container--top-right {
  top: 3.5em;
}
.enroll-verification .Toastify .Toastify__toast {
  font-family: var(--font-family-lato);
  font-size: 14px;
  min-height: fit-content;
  border-left: 10px solid var(--red);
  color: var(--grey-darker);
}
.enroll-verification .Toastify .Toastify__toast-body {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
