.resources {
  position: relative;
}

.resources > p {
  margin-top: 0;
}

.btn-close,
.btn-focus {
  background-color: transparent;
  border-width: 0;
  box-shadow: none;
  cursor: pointer;
  padding: 4px;
  line-height: 1;
  position: absolute;
  top: 20px;
  z-index: 5;
}

.btn-close span {
  color: var(--red);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  gap: 6px;
}

.btn-close span img {
  margin-top: 1px;
}

.btn-focus img:hover {
  filter: invert(37%) sepia(81%) saturate(6117%) hue-rotate(16deg)
    brightness(96%) contrast(94%);
}

.resources-wrap {
  position: relative;
  display: flex;
}

.resources-left {
  position: relative;
  width: 100%;
}

.resources-right--inner::-webkit-scrollbar,
.toggle .resources-left--inner::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.resources-right--inner::-webkit-scrollbar-thumb,
.toggle .resources-left--inner::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--grey-lighter);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.resources-left--inner.hide {
  display: none;
}
.resources-right.max-width {
  width: 99%;
  margin: auto;
}
.resources-right.max-width .resources-arrow {
  display: none;
}

.resources-right {
  position: relative;
  height: fit-content;
  flex-shrink: 0;
  width: 100%;
}

.resources-left,
.resources-right {
  box-sizing: border-box;
  margin-bottom: 30px;
}

.resources-right,
.resources-row {
  background-color: var(--white-pure);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}

.resources-row {
  align-items: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 10px;
  transition: all 0.2s ease-in-out;
  padding: 30px 20px 20px;
  position: relative;
}

.resources-right {
  padding: 10px 0 0 0;
}

.resources-right--inner {
  padding: 20px;
}

.resources-arrow {
  background-color: var(--white-pure);
  display: none;
  position: absolute;
  height: 30px;
  width: 30px;
  box-shadow: -2px 3px 3px #00000015;
  left: -12px;
  margin-top: 70px;
  z-index: 0;
  transform: rotate(45deg);
}

.resources-arrow.remove-arrow {
  display: none;
}

.resources-row.active,
.resources-row:hover {
  background-color: var(--hover-row);
  border-color: var(--red);
}

.resources-row h3 {
  color: var(--blue-purple);
  margin: 0;
  line-height: var(--line-spacing-30);
}

.resources-row p {
  color: var(--grey-dark);
  margin: 10px 0 0 0;
  line-height: var(--line-spacing-20);
}

.resources-right h2 {
  color: var(--blue-purple);
}

.resources-right h2 + p {
  color: var(--grey-dark);
  line-height: var(--line-spacing-20);
}

.resources-content {
  position: relative;
  padding: 0 10px;
  z-index: 2;
}

.resources-content {
  color: var(--grey-darker);
}

.resources-content h3 {
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-20);
  margin: 30px 0 10px;
}

.resources-content li,
.resources-content p {
  line-height: var(--line-spacing-20);
}

.resources-content li {
  margin-bottom: 5px;
}

.resources-content a,
.resources-content b {
  color: var(--red);
  font-size: var(--font-size-14);
}

.resources-content a {
  text-decoration: none;
}

.resources-content a:hover {
  text-decoration: underline;
}

.resources-content hr {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: var(--grey-disabled);
  margin-left: -10px;
}
.resource-msg {
  position: absolute;
  right: 1rem;
  top: 0;
}

.resources-right img.resource-bg {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0.2;
  z-index: 1;
}

.page-intro-showmore {
  display: none;
}

.btn-focus + span {
  background-color: var(--white-pure);
  cursor: text;
  display: none;
  top: -12px;
  padding: 5px 10px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 2px 4px #00000029;
  font-size: 12px;
  border: 1px solid var(--tt-border-color);
  right: 10px;
  text-wrap: nowrap;
}
.btn-focus + span::before {
  background-color: var(--white-pure);
  content: "";
  display: block;
  height: 8px;
  box-shadow: 2px 2px 2px #00000012;
  position: absolute;
  width: 8px;
  bottom: -4px;
  left: 50%;
  margin-left: -17px;
  transform: rotate(45deg);
}
.btn-focus + span::after {
  content: "";
  display: block;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
}
.btn-focus + span:hover,
.btn-focus:hover + span {
  display: block;
}

@media (min-width: 1200px) {
  /* .resources-right, */
  .resources-row {
    padding: 30px;
  }

  .resources-content {
    padding: 10px 30px 0;
  }
}

@media (max-width: 1199px) {
  .resources-row img {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .resources-row {
    padding: 30px 20px;
  }
  .btn-close {
    right: 20px;
  }
  .btn-focus {
    right: 50px;
  }
  .btn-close span {
    display: none;
  }
  .resources-left.toggle .resources-left--inner {
    padding-right: 10px;
  }
  .resources-left.toggle {
    padding-right: 14px;
  }
  .toggle .resources-left--inner {
    height: 780px;
  }
  .resources-right {
    position: relative;
    width: 62%;
  }
  .toggle .resources-left--inner {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .resources-arrow {
    display: block;
  }
  .resources-right {
    padding: 10px 10px 0;
  }
  .resources-right--inner {
    height: 750px;
    overflow-y: auto;
  }
}

@media (max-width: 991px) {
  .btn-focus {
    display: none;
  }
  .btn-close {
    left: 12px;
  }
  .btn-close > img {
    display: none;
  }
  .resources-left.toggle {
    display: none;
  }
  .resources-row img {
    width: 120px;
  }
}

@media (min-width: 768px) {
  .resources-row {
    gap: 30px;
    margin-bottom: 20px;
  }
  .resources-right img.resource-bg {
    right: 20px;
    bottom: 20px;
    width: 400px;
  }
  .resources-row p,
  .resources-content ul,
  .resources-content ol {
    font-size: var(--font-size-14);
  }

  .resources-row img {
    height: 113px;
  }
}

@media (max-width: 767px) {
  .resources-row {
    overflow: hidden;
    margin-bottom: 10px;
  }
  .resources-row div {
    overflow: hidden;
  }
  .resources-row h3 {
    font-size: 14px;
  }
  .resources-row p,
  .resources-content ul,
  .resources-content ol {
    font-size: var(--font-size-12);
  }
  .resources-content ul,
  .resources-content ol {
    padding-left: 16px;
  }
  .resources-row p {
    margin-top: 2px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .resources-row img {
    width: 90px;
  }
  .resources-right img.resource-bg {
    width: 150px;
  }
  .resources-right h2 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .resources-right p,
  .resources-right a,
  .resources-right b {
    font-size: 12px;
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .page-intro.hide {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-intro-showmore {
    display: block;
    bottom: 220px;
    left: 10px;
    margin-bottom: 0.8rem;
    margin-top: -15px;
  }
  .page-intro-showmore p {
    display: flex;
    padding: 0px;
    margin: 0px;
    color: var(--red);
    cursor: pointer;
  }
  .resource-msg {
    top: 5px;
  }
}
