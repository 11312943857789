.storybook-input-label {
  display: none;
  margin-bottom: 5px;
  font-size: var(--font-size-12);
}

.storybook-input-label--show {
  display: block;
}

.storybook-input {
  border: 1px solid var(--grey-lighter);
  padding: 10px;
  border-radius: 4px;
  font-size: var(--font-size-14);
  color: var(--grey-darker);
}

.storybook-input[disabled] {
  border: 1px solid var(--grey-lighter);
  color: var(--grey-light);
}

.storybook-input-help {
  display: block;
  margin-top: 5px;
  font-size: var(--font-size-12);
  color: var(--grey-light);
}

.storybook-input--small {
  width: 50px;
}

.storybook-input--medium {
  width: 300px;
}

.storybook-input--large {
  width: 400px;
}

@media screen and (max-width: 450px) {
  .storybook-input--small,
  .storybook-input--medium,
  .storybook-input--large {
    max-width: 80%;
  }
}
