.storybook-progressbar-bckgrnd-bar {
  background-color: #EEEEEE;
  border-radius: 1.5em;
  position: relative;
}

.scale-25, .scale-50, .scale-75 {
  position: absolute;
  display: flex;
  left: 2.60em;
  top: .30em;
  bottom: .2em;
  justify-content: flex-end;
  color: #6B6B6B;
  font-size: var(--font-size-14);
}

.scale-25 {
  width: 25%;  
}

.scale-50 {
  width: 50%;
}

.scale-75 {
  width: 75%;
}

.storybook-progressbar-outer-tiny {
  height: 6px;
}

.storybook-progressbar-outer-small {
  height: 14px;
}

.storybook-progressbar-outer-standard {
  height: 25px;
}

.storybook-progressbar-outer-large {
  height: 40px;
}

.storybook-progressbar-outer-big {
  height: 66px;
}

.storybook-progressbar-progress {
  font-family: var(--font-family-lato);
  background-color: var(--green);
  border-radius: 1.5em 0 0 1.5em;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 5px;
  max-width:100%;
}

.storybook-progressbar-progress span {
  position: relative;
  left: 30px;
  color: var(--green-darkest);
  font-weight: var(--font-weight-bold); 
  font-size: var(--font-size-14); 
}

/* Styles for different width percentages */
.storybook-progressbar-progress[data-width="25"] {
  /* Styles for 25% width */
  background-color: var(--red);
}

.storybook-progressbar-progress[data-width="50"] {
  /* Styles for 50% width */
  background-color: var(--yellow);
}

.storybook-progressbar-progress[data-width="75"] {
  /* Styles for 75% width */
  background-color: var(--blue);
}

/* Add more styles for other width percentages as needed */

.storybook-progressbar-progress:only-child {
  border-radius: 1.5em;
}

.storybook-progressbar-progress:only-child span{
  position: unset;
  left:0;
  color: var(--white-pure);
}

.storybook-progressbar-progresslabel-standard {
  color: white;
  font-size: 13px;
}

.storybook-progressbar-progresslabel-large {
  color: white;
  font-size: 20px;
}

.storybook-progressbar-progresslabel-big {
  color: white;
  font-size: 30px;
}

.storybook-progressbar-label {
  display: inline-flex;
  width: 48%; 
  font-family: var(--font-family-lato);
  font-size: var(--font-size-15);
  margin: 0.5em 0.25em;
  font-weight: var(--font-weight-bold);
}

.storybook-progressbar-desc {
  display: inline-flex;
  width: 48%;
  justify-content: flex-end;
  font-family: var(--font-family-lato);
}

@media (max-width: 428px) {
  .storybook-progressbar-label,
  .storybook-progressbar-desc {
    width: 100%;
  }
}