.profile {
  margin: 0;
  padding: 0;
}

.storybook-aside {
  padding: unset;
}

.profile-content {
  display: flex;
  gap: 20px;
}

.profile-content > div {
  width: 100%;
}

.profile-intro h1 {
  font-size: var(--font-size-18);
  margin-bottom: 0;
}

.profile-intro p {
  font-size: var(--font-size-12);
}

.profile--row {
  justify-content: space-between;
  display: flex;
  margin-bottom: 10px;
}

.profile--row + hr {
  margin: 40px 0 0;
}

.profile--row h5 {
  color: var(--grey-dark);
  margin: 10px 0;
}

.profile--row .profile--tracking {
  color: var(--red);
  margin-bottom: 5px;
}

.white-container {
  background-color: var(--white-pure);
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
}
.profile-details.white-container {
  padding: 20px;
  margin-bottom: 50px;
}

.showInDesktop {
  display: none;
}

.profile-img {
  grid-area: img;
  max-width: 96px;
  color: var(--grey-darker);
}

.profile-name {
  grid-area: name;
  font-size: var(--font-size-15);
  align-self: end;
  margin-top: 0;
}

.profile--cccid {
  grid-area: cccid;
  font-size: var(--font-size-15);
  align-self: end;
  margin: 0;
}
.profile-details .profile--cccid.showInMobile {
  text-align: unset;
}

.profile .storybook-button {
  grid-area: btn;
  align-self: start;
  font-weight: var(--font-weight-bold);
}

.profile-card {
  display: grid;
  grid-template-areas:
    "img name"
    "img cccid"
    "img btn";
  grid-template-rows: auto 5px 1fr;
  gap: 5px 11px;
  justify-content: center;
  padding-bottom: 1rem;
}

.profile-details p {
  font-size: var(--font-size-12);
  color: var(--grey-dark);
}

.profile--userinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile--userinfo span:nth-child(2) {
  font-weight: var(--font-weight-bold);
}

.profile .red-color {
  color: var(--red-dark);
}

.profile hr {
  border-top: 1px solid var(--grey-disabled);
}

.profile-details .profile-card + hr {
  margin-bottom: 1.5rem;
  width: 80%;
}

.profile--userstatus {
  border-top: 1px solid var(--grey-disabled);
  padding-block: 10px;
  margin-top: 30px;
  text-align: center;
}

.profile--userstatus h3,
.profile--userstatus p {
  font-size: var(--font-size-12);
  color: var(--grey-dark);
}

.profile .storybook-aside {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 100%;
}
.profile .storybook-aside p {
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-20);
  color: var(--grey);
}

.profile .storybook-aside a {
  font-size: var(--font-size-14);
}

.profile .storybook-aside button {
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  max-width: 220px;
  width: 100%;
}

.profile .storybook-aside p:last-child {
  color: var(--grey-dark);
}

.profile--userstatus h3 {
  margin-top: 1rem;
  margin-bottom: 0;
}

.profile--userstatus p {
  margin-top: 5px;
}

.profile-aside-block a {
  font-weight: bold;
}

.profile a:hover {
  text-decoration: underline;
}

.profile .storybook-aside h2 {
  border-bottom: 1px solid var(--grey-disabled);
  color: var(--blue-purple);
  font-size: var(--font-size-15);
  line-height: var(--line-spacing-24);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.storybook-aside p.profile-aside--action,
.profile-aside--action a {
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-20);
  color: var(--blue-purple);
  font-weight: var(--font-weight-bold);
}

.profile-aside--action {
  margin-bottom: 10px;
}

.profile .profile-aside-block {
  margin-bottom: 40px;
  padding-inline: 15px;
}

.profile .profile-aside-block:last-child {
  border-top: 1px solid var(--grey-disabled);
  padding-top: 40px;
}

.profile .storybook-avatar-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
}

.profile .storybook-modal-body {
  max-width: unset;
}

.profile .Toastify .Toastify__toast--success {
  background: var(--white-pure);
}
.profile .Toastify .Toastify__toast-container {
  color: var(--grey-darker);
  background: transparent;
  width: auto;
}
.profile .Toastify .Toastify__toast-container--top-right {
  top: 3.5em;
}
.profile .Toastify .Toastify__toast {
  font-family: var(--font-family-lato);
  font-size: 14px;
  min-height: fit-content;
  border-left: 10px solid var(--red);
  color: var(--grey-darker);
}
.profile .Toastify .Toastify__toast-body {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.profile-img {
  max-width: 96px;
  width: 96px;
}

.profile .alert {
  margin-top: 20px;
}
.profile .alert h3 {
  margin-bottom: 0;
  font-weight: 500;
}
.profile .alert p {
  margin-top: 0;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .profile header {
    z-index: 999;
    position: relative;
  }

  .profile-intro h1 {
    font-size: var(--font-size-24);
    line-height: var(--line-spacing-30);
  }
  .profile-intro p {
    font-size: var(--font-size-14);
    padding-top: 10px;
  }
  .profile-img {
    max-width: 200px;
    width: 200px;
  }

  .profile-details.white-container {
    --container-pad-80: 2rem;
    min-height: 660px;
  }

  .profile-card {
    grid-area: card;
    grid-template-areas:
      "img"
      "name"
      "cccid"
      "btn";
    align-items: center;
    grid-template-rows: max-content;
    padding-top: var(--container-pad-80);
    padding-inline: var(--container-pad-80);
  }

  .profile-card .profile-img,
  .profile-card .profile-name,
  .profile-card .storybook-button,
  .profile-details .profile--cccid {
    justify-self: center;
    align-self: start;
  }

  .profile .storybook-button {
    margin-top: 1.875rem;
  }

  .profile--userstatus {
    grid-area: userstatus;
    margin-top: 0;
    padding-bottom: 50px;
    padding-top: var(--container-pad-80);
  }

  .profile-card,
  .profile--userstatus {
    padding-inline: var(--container-pad-80);
  }

  .profile-details hr {
    grid-area: hr;
  }

  .profile-details {
    display: grid;
    grid-template-areas:
      "card"
      "hr"
      "userinfo"
      "userstatus";
    grid-template-columns: 5fr;
    grid-template-rows: max-content;
    gap: 20px;
    padding: 0;
    /* max-width: calc(1366px - 291px - 138px); */
    justify-self: start;
    width: 100%;
  }

  .profile--userstatus h2,
  .profile-card h2 {
    margin-top: 0;
    padding-top: 0;
    font-size: var(--font-size-28);
  }

  .profile-card h2 {
    padding-top: 2.5rem;
  }

  .profile-course-info {
    padding-bottom: 50px;
  }

  .profile--userstatus h3 {
    font-size: var(--font-size-16);
    margin-top: 2.5rem;
  }

  .profile .storybook-aside p,
  .profile-details p {
    font-size: var(--font-size-14);
  }

  .profile .storybook-aside h2 {
    font-size: var(--font-size-18);
    line-height: var(--line-spacing-30);
    padding-inline: 30px;
  }

  .profile .storybook-avatar-list {
    gap: 3.125rem;
  }

  .profile .storybook-avatar-list img {
    max-width: unset;
  }
  .profile .storybook-modal-body {
    max-width: inherit;
  }

  .profile--row > div:nth-child(1) {
    width: 50%;
  }

  .profile--row > div:nth-child(2) {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .profile-content {
    flex-direction: column;
  }

  .profile--row {
    flex-direction: column;
  }

  .profile--row a,
  .profile--row h5 {
    font-size: var(--font-size-12);
  }

  .profile--row p {
    margin-bottom: 5px;
  }

  .profile--device-date {
    display: none;
  }
}

@media (min-width: 1366px) {
  .profile-details.white-container {
    --container-pad-80: 5rem;
    padding: 0;
  }

  .profile-details .profile-card + hr {
    width: 50%;
  }

  .profile-card {
    grid-area: card;
    grid-template-areas:
      "img"
      "cccid"
      "btn";
    align-items: center;
    grid-template-rows: max-content;
    padding-top: var(--container-pad-80);
    gap: 0;
  }

  .profile--userstatus {
    border-top: 0;
    border-left: 1px solid var(--grey-disabled);
    text-align: unset;
  }

  .profile-card .storybook-button {
    margin-top: 1.25rem;
    margin-bottom: 0;
  }

  .profile-details.white-container {
    grid-template-areas:
      "card userstatus"
      "hr userstatus"
      "userinfo userstatus";
    grid-template-columns: 2fr 3fr;
  }

  .showInDesktop {
    display: block;
  }
  .showInMobile {
    display: none;
  }

  .storybook-avatar-list .avatar {
    width: 150px;
  }
  /* Default and secondary button style override for avatar modal to basic styling */
  .profile .storybook-modal .storybook-button--default,
  .profile .storybook-modal .storybook-button--secondary {
    color: var(--red);
    background-color: var(--white-pure);
    border: 1px solid var(--grey-light);
  }
  /* hover override*/
  .profile .storybook-modal .storybook-button--default:hover,
  .profile .storybook-modal .storybook-button--secondary:hover {
    color: var(--white-pure);
    background-color: var(--red);
    border-color: var(--red);
  }
  .profile-details .profile--cccid {
    text-align: center;
    margin-top: 1rem;
  }

  .profile--row > div:nth-child(1) {
    width: 60%;
  }

  .profile--row > div:nth-child(2) {
    width: 40%;
  }
}
