.in-content {
  text-align: center;
  font-weight: normal;
}

.in-content b {
  color: var(--grey-darker);
}


.in-learning-row {
  display: flex;
  gap: 20px;
  margin: 30px 0 50px;
}

.in-learning-col {
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
}

.in-learning-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.in-learning-links > div {
  border: 1px solid var(--grey-disabled);
  border-radius: 5px;
  padding: 20px;
}

.in-learning-links p {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .in-learning-links > div {
    padding: 20px 35px;
  }

  .in-content button {
    min-width: 220px;
  }

  .in-learning-col {
    flex-direction: column;
    width: 33.3333333%;
  }

  .in-learning-col img {
    width: 72px;
  }

  .in-learning-links p {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .in-modal .storybook-modal-icon-container img {
    width: 64px;
  }

  .in-modal h2 {
    font-size: var(--font-size-18);
  }
  
  .in-learning-links {
    flex-direction: column;
  }

  .in-learning-row {
    flex-direction: column;
  }
  
  .in-content,
  .in-content p,
  .in-content span,
  .in-learning-links p,
  .in-learning-links a {
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-18);
  }

  .in-learning-col span {
    text-align: left;
  }

  .in-learning-col img {
    width: 32px;
  }

  .in-content button {
    min-width: 192px;
  }
}