.ar-content {
  font-size: var(--font-size-14);
  position: relative;
}

.ar-content:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  height: 1px;
  background-color: var(--grey-lighter);
  width: 100%;
}

.ar-content--withdraw-dates {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  max-width: 680px;
}

.ar-content--withdraw-dates div {
  border-left: 2px solid var(--red);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-14);
  gap: 4px;
  padding: 0 10px;
}

.ar-content--withdraw-dates div span {
  color: #707070;
}

.ar-content--withdraw-dates div strong {
  color: var(--grey-dark);
}

.ar-content--withdraw-status {
  display: flex;
  gap: 8px;
}

.ar-content--withdraw-status img {
  margin-bottom: -5px;
}

.ar-warning-message .storybook-tooltip-container .storybook-tooltip-text,
.ar-content--withdraw-status .storybook-tooltip-container .storybook-tooltip-text {
  min-width: 220px;
  margin-top: -10px;
  margin-left: -160px;
}

.ar-warning-message .storybook-tooltip-text--top::before,
.ar-content--withdraw-status .storybook-tooltip-text--top::before {
  top: 100%;
  margin-left: -5px;
  border: 6px solid transparent;
  border-top-color: var(--tt-border-color);
  left: auto;
  right: 22%;
}

.ar-warning-message .storybook-tooltip-text--top::after,
.ar-content--withdraw-status .storybook-tooltip-text--top::after {
  left: auto;
  right: 20%;
}

.ar-content h2 {
  color: var(--grey-darker);
  font-weight: var(--font-weight-medium);
}

.ar-content p strong {
  color: var(--grey-darker);
}

.ar-content .underline {
  font-size: var(--font-size-14);
}

.ar-content sup {
  margin-left: 2px;
  font-size: var(--font-size-12);
}

.ar-content .ar-modal sup {
  font-size: 26px;
  font-weight: var(--font-weight-regular);
}

.ar-form {
  border-top: 1px solid var(--border-color);
  padding: 20px 0 50px;
  max-width: 660px;
  margin-top: 20px;
}

.ar-form,
.ar-form--row,
.ar-form--row-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ar-form p,
.ar-form h3 {
  margin: 0;
}

.ar-form strong,
.ar-form h3 {
  color: var(--grey-dark);
  line-height: var(--line-spacing-20);
}

.ar-form--row-inner {
  margin-left: 15px;
}

.ar-form--row label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ar-form--row label input {
  margin: 2px 0 0 0;
}

.ar-form--row textarea {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-family:  var(--font-family-lato);
  padding: 10px;
  max-width: 500px;
  min-height: 80px;
  resize: vertical;
}

.ar-form--row-confirmation h3 {
  margin-bottom: 10px;
}

.ar-form--buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px
}

.ar-form--buttons button {
  min-width: 100px;
}

.ar-warning-message h3 {
  margin-top: 10px;
}

.ar-modal .storybook-modal-body {
  max-width: 90%;
}

.ar-modal .storybook-modal-body .underline {
  font-size: var(--font-size-28);
  font-weight: var(--font-weight-bold);
}

.ar-content .storybook-tooltip-text {
  line-height: initial;
  font-weight: initial;
  text-align: left;
}

.ar-content .ar-modal h2 {
  line-height: 40px;
}

.ar-content .ar-modal .storybook-modal-footer button {
  min-width: 140px;
}

.ar-modal .storybook-tooltip-text--top {
  top: -55px;
  margin-left: -160px;
}

.ar-modal .storybook-tooltip-text--top::before {
  top: 100%;
  margin-left: -5px;
  border: 6px solid transparent;
  border-top-color: var(--tt-border-color);
  left: auto;
  right: 12%;
}

.ar-modal .storybook-tooltip-text--top:after {
  left: auto;
  right: 10%;
}

.transcript-form {
  font-size: var(--font-size-14);
  margin: 50px 0;
}

.transcript-form > h3 {
  margin-bottom: 20px;
}

.transcript-form--inputs-row {
  align-items: center;
  display: flex;
  gap: 10px;
}

.transcript-form--inner > .transcript-form--inputs-row {
  margin-bottom: 10px;
}

.transcript-form--inner h3 {
  margin: revert;
}

.transcript-form--inputs {
  padding-left: 30px;
}

.transcript-form input[type="checkbox"][disabled] + svg {
  color: var(--grey-lighter);
}

.transcript-form input[type="email"][disabled] {
  background-color: transparent;
  border-width: 0;
  color: var(--grey-dark);
  padding-left: 0;
}

.transcript-form--inputs-row {
  position: relative;
  margin-bottom: 20px;
}

.transcript-form--inputs-row .error-message {
  color: var(--red-dark);
  position: absolute;
  bottom: -16px;
  font-size: 12px;
}

.transcript-form--inputs-row .storybook-checkbox-container ~ .error-message {
  padding-left: 34px;
}

.transcript-form--inputs-row .storybook-input-label {
  display: none;
}

.transcript-form--buttons {
  margin-top: 50px;
  display: flex;
  gap: 10px;
}

.transcript-form--buttons button {
  min-width: 100px;
}

.transcript-form .icon-minus,
.transcript-form .icon-plus {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  height: 20px;
  width: 20px;
}

.ar-content--error {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0;
}

.ar-content--error img {
  height: 48px;
  width: 48px;
}

.ar-content--error b {
  color: var(--grey-darker);
  line-height: var(--line-spacing-20);
}

.ar-content--certificate-row {
  align-items: center;
  color: var(--grey-dark);
  display: flex;
  font-size: var(--font-size-14);
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ar-content--certificate-row:first-of-type {
  margin-top: 50px;
}

.ar-content .skeleton {
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 1200px) {
  .ar-content:before {
    top: -20px;
  }
}

@media screen and (min-width: 768px) {
  .ar-content--withdraw-dates {
    margin: 40px 0;
  }

  .ar-form--row-confirmation {
    margin-top: 20px;
  }

  .ar-form--row-inner {
      margin-left: 25px;
  }

  .ar-form > h3:first-of-type,
  .ar-form > p:first-of-type {
    margin-bottom: 20px;
  }

  .ar-form--buttons {
    margin-top: 30px
  }

  .ar-content--certificate-row {
    gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ar-content--withdraw-dates {
    gap: 20px;
    flex-direction: column;
  }

  .ar-form--row label {
    font-size: var(--font-size-12);
  }

  .ar-content--certificate-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
