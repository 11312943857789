.page-intro-showmore {
  display: none;
}

@media (max-width: 480px) {
  .page-intro.hide {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-intro-showmore {
    display: block;
    bottom: 220px;
    left: 10px;
    margin-bottom: 0.8rem;
    margin-top: -15px;
  }
  .page-intro-showmore p {
    display: flex;
    padding: 0px;
    margin: 0px;
    color: var(--red);
    cursor: pointer;
  }
}
