.storybook-button {
  font-family: var(--font-family-lato);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-900);
  border: 0;
  border-radius: 0.375em;
  position: relative;
  cursor: pointer;
  display: inline-block;
  line-height: 1rem;
}

.storybook-button.btn-loading {
  pointer-events: none;
}

.storybook-button[disabled] {
  cursor: default;
}

.storybook-button--default {
  color: white;
  background-color: var(--red);
  border: 1px solid var(--red);
}
.storybook-button--default:hover {
  background-color: var(--button-default-hover);
}
.storybook-button--default:active {
  background-color: var(--button-default-active);
}

.storybook-button--basic {
  color: var(--red);
  background-color: var(--white-pure);
  border: 1px solid var(--grey-light);
}
.storybook-button--basic:hover {
  color: var(--white-pure);
  background-color: var(--red);
  border-color: var(--red)
}
.storybook-button--basic:active {
  background-color: var(--button-default-active);
  color: var(--white-pure);
}

.storybook-button--with-icon {
  color: var(--grey-darker);
  background-color: var(--grey-lighter);
}
.storybook-button--with-icon:hover {
  background-color: #4d54e6;
}
.storybook-button--with-icon:active {
  background-color: #1921b3;
}

.storybook-button--secondary {
  color: var(--grey-darker);
  background-color: var(--grey-btn);
  border: 1px solid var(--grey-btn);
}

.storybook-button--secondary:hover {
  color: var(--grey-btn);
  background-color: var(--grey-darker);
  border: 1px solid var(--grey-darker);
}

.storybook-button--tiny {
  font-size: var(--font-size-11);
  padding: 3px 9px;
  border-radius: 0.25em;
}
.storybook-button--small {
  font-size: var(--font-size-12);
  padding: 4px 20px 5px 20px;
}
.storybook-button--medium {
  font-size: var(--font-size-14);
  padding: 10px 20px;
}
.storybook-button--large {
  font-size: var(--font-size-16);
  padding: 12px 24px;
}

.storybook-button:disabled {
  background-color: var(--button-bg-disabled);
  border-color: var(--button-bg-disabled);
  color: var(--button-color-disabled);
}

.storybook-button--loading {
  height: 0.65em !important;
}

.storybook-button.transparent {
  color: transparent;
}

.storybook-button .loading {
  animation: loading 1s steps(5) infinite;
  color: #fff;
  clip-path: inset(0 100% 0 0);
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 2px;
}

.storybook-button--basic .loading {
  color: var(--red);
}

.storybook-button--primary .loading {
  color: initial;
}

.storybook-button--secondary .loading {
  color: var(--grey-darker);
}

.storybook-button--secondary:hover .loading,
.storybook-button--basic:hover .loading {
  color: #fff;
}

@keyframes loading {to{clip-path: inset(0 -1ch 0 0)}}

@media (max-width:320px) {
  .storybook-button--tiny {
    font-size: var(--font-size-10);
    padding: 3px 9px;
    border-radius: 0.25em;
  }
  /* .storybook-button--small {
    font-size: var(--font-size-11);
    padding: 10px 16px;
  } */
  .storybook-button--medium {
    font-size: var(--font-size-12);
    padding: 7px 12px;
  }
  .storybook-button--large {
    font-size: var(--font-size-14);
    padding: 12px 24px;
  }
}