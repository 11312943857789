.storybook-myteam main {
  display: flex;
  flex-direction: column;
  padding: 0 5% 40px;
  background-color: var(--cream-light);
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 2em;
}

.storybook-myteam .white-container {
  background: var(--white-pure) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--grey-lighter);
  border-radius: 8px;
  margin-top: 1em;
}
.storybook-myteam-members .white-container {
  padding: 0;
}
.storybook-myteam-members .storybook-myteam-preepp {
  min-height: 280px;
}
.storybook-myteam h1 {
  display: none;
}

.storybook-myteam-members {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 20px;
  max-width: 1020px;
}

.storybook-myteam-members p {
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-20);
}

.storybook-myteam-member--bio {
  color: var(--red);
  cursor: pointer;
  display: block;
  margin-top: 5px;
}

.storybook-myteam-instructors,
.storybook-myteam-counselors {
  max-width: 500px;
  margin-top: 1em;
}

.storybook-myteam-instructors {
  position: relative;
}

.storybook-myteam h2 {
  color: var(--blue-purple);
}

.storybook-myteam h2,
.storybook-myteam h3,
.storybook-myteam p {
  text-align: center;
}

/* Card modifications */
.storybook-myteam-instructors-list-item {
  display: flex;
  flex-wrap: wrap;
  max-height: 275px;
  position: relative;
}
.storybook-myteam-instructors-list-item:only-child {
  margin-bottom: 36px;
}
.storybook-myteam-instructors-list-item:not(.show) {
  overflow-y: hidden;
}
.storybook-myteam-instructors-list-item .storybook-card-container {
  flex-basis: 48%;
}
.storybook-myteam-instructors-list-item
  .storybook-card-container
  .storybook-card-content {
  width: 100%;
}

.storybook-myteam .storybook-card-container .storybook-card-image img {
  border-radius: 50%;
  object-fit: cover;
  height: 64px;
  width: 64px;
}

.storybook-myteam
  .storybook-card-container
  .storybook-card-content
  .storybook-card-title {
  font-size: var(--font-size-18);
  margin-bottom: 0;
}

.storybook-myteam-instructors-show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px;
}

.storybook-myteam-instructors-show-more img {
  width: 14px;
}

.storybook-myteam-instructors-show-more .expand-rotate {
  transform: rotate(180deg);
}

.storybook-myteam-slack {
  grid-area: slack;
  justify-self: end;
}
.storybook-myteam-email {
  grid-area: email;
  max-width: fit-content;
  position: relative;
}
.storybook-myteam-email a + span {
  background-color: var(--cream-light);
  cursor: text;
  display: none;
  top: -30px;
  padding: 5px 10px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 2px 4px #00000029;
  font-size: 12px;
  border: 1px solid var(--tt-border-color);
  left: -50%;
  text-wrap: nowrap;
}
.storybook-myteam-email a + span::before {
  background-color: var(--cream-light);
  content: "";
  display: block;
  height: 8px;
  box-shadow: 2px 2px 2px #00000012;
  position: absolute;
  width: 8px;
  bottom: -4px;
  left: 50%;
  margin-left: -17px;
  transform: rotate(45deg);
}
.storybook-myteam-email a + span::after {
  content: "";
  display: block;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
}
.storybook-myteam-email a + span:hover,
.storybook-myteam-email a:hover + span {
  display: block;
}
.storybook-myteam-phone {
  align-items: center;
  display: flex;
  gap: 5px;
  font-size: var(--font-size-14) !important;
}

.storybook-myteam-member-contact {
  column-gap: 20px;
  display: flex;
  justify-content: center;
}

.storybook-myteam-counselors-list.white-container,
.storybook-myteam-instructors-list-item.white-container {
  height: 315px;
}
.storybook-myteam-instructors-list {
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  min-height: 315px;
}

.storybook-myteam-instructors-list-item {
  transition: max-height 0.5s ease-in-out;
}

.storybook-myteam-instructors-list-item.show,
.storybook-myteam .white-container.show {
  height: auto;
  max-height: 100%;
  transition: max-height 0.5s ease-in-out;
}

.storybook-myteam-counselor-list-item .storybook-card-container {
  max-width: 100%;
}

.storybook-myteam .storybook-card-title {
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-23);
  color: var(--grey-darker);
  font-weight: var(--font-weight-bold);
  /* font: normal normal bold 18px/23px Lato; */
}

.storybook-myteam-course-details,
p.storybook-myteam-course-details {
  font-size: var(--font-size-14);
  color: var(--blue-darker);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-spacing-20);
}

.storybook-myteam-course-details.alt-color {
  color: var(--brown);
}

.storybook-myteam-course-details span {
  color: var(--grey-light);
  margin: 0 0.5em;
}

.storybook-myteam-title {
  font-size: var(--font-size-14);
  color: var(--green-darker);
  font-weight: var(--font-weight-regular);
  margin: 0.1em;
}

.storybook-myteam .storybook-button {
  margin: 0.35em 0;
}

.storybook-myteam .storybook-button--small {
  padding: 3px 10px 3px 10px;
  min-width: 145px;
}

.storybook-myteam-member-contact p > a {
  align-items: center;
  display: flex;
  color: var(--grey-darker);
  font-size: var(--font-size-14);
  gap: 5px;
  text-decoration: none;
}

.storybook-myteam-email svg,
.storybook-myteam-phone svg {
  font-size: var(--font-size-16) !important;
  margin-right: 0.15em;
  filter: grayscale(100%) brightness(0%);
}

.storybook-myteam .storybook-card-container {
  border: 0;
  padding: 30px 20px;
}

.storybook-myteam-copy {
  max-width: 950px;
  border-bottom: 1px solid var(--grey-disabled);
  padding-bottom: 2em;
  font-size: var(--font-size-14);
}

.storybook-myteam-instructional-faculty,
.storybook-myteam-peer-tutors,
.storybook-myteam-academic-success-counselors {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  gap: 1em;
  max-width: 950px;
  align-items: center;
}

.storybook-myteam-instructional-faculty a,
.storybook-myteam-peer-tutors a,
.storybook-myteam-academic-success-counselors a {
  color: var(--red-dark);
  text-decoration: none;
}

.storybook-myteam-instructional-faculty a,
.storybook-myteam-peer-tutors a,
.storybook-myteam-academic-success-counselors a {
  color: var(--red-dark);
  text-decoration: none;
}

.storybook-myteam-instructional-faculty img,
.storybook-myteam-peer-tutors img,
.storybook-myteam-academic-success-counselors img {
  flex-basis: 50%;
  max-width: 280px;
  flex-grow: 1;
}

.storybook-myteam-instructional-faculty div,
.storybook-myteam-peer-tutors div,
.storybook-myteam-academic-success-counselors div {
  flex-grow: 2;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.storybook-myteam-peer-tutors div {
  align-items: flex-end;
}

.storybook-myteam-instructional-faculty h2,
.storybook-myteam-peer-tutors h2,
.storybook-myteam-academic-success-counselors h2 {
  margin-bottom: 0;
  font-size: var(--font-size-18);
}

.storybook-myteam-peer-tutors h2 {
  align-self: flex-start;
}

.storybook-myteam-instructional-faculty p,
.storybook-myteam-peer-tutors p,
.storybook-myteam-academic-success-counselors p {
  text-align: left;
  font-size: var(--font-size-14);
  margin-top: 0.05em;
}

.storybook-myteam-assistance {
  border-top: 1px solid var(--grey-disabled);
  padding-top: 20px;
  margin-top: 40px;
  font-size: var(--font-size-12);
  max-width: 1000px;
  width: 100%;
}

.storybook-modal.modal-bio {
  white-space: normal;
}

.modal-bio .storybook-modal-icon-container {
  background-color: var(--grey-disabled);
  border-radius: 50%;
  overflow: hidden;
  height: 150px;
  margin: 0 auto 25px;
  width: 150px;
}

.modal-bio .storybook-modal-icon-container img {
  border-radius: 50%;
  max-width: 100%;
}

.modal-bio--row {
  margin-bottom: 20px;
}

.modal-bio .storybook-modal-body h2 {
  color: var(--grey-darker);
  margin: 10px 0 20px;
}

.modal-bio .storybook-modal-body h2,
.modal-bio .storybook-modal-body b,
.modal-bio .storybook-modal-body p {
  justify-content: flex-start;
  text-align: left;
}

.modal-bio .storybook-modal-body p {
  font-size: var(--font-size-14);
}

.modal-bio .storybook-myteam-member-contact {
  grid-template-columns: auto;
  justify-content: flex-start;
}

.modal-bio .storybook-modal-footer button {
  width: 110px;
}

/* 1052 break point */
@media (max-width: 1160px) {
}

@media (min-width: 801px) {
  .storybook-myteam-counselor-list-item .storybook-card-container {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .storybook-myteam-instructors,
  .storybook-myteam-counselors {
    grid-auto-flow: column;
    max-width: 800px;
  }
  .storybook-myteam-instructors-list {
    position: relative;
    top: 0;
    left: 0;
  }

  .storybook-myteam-instructors-list-item .storybook-card-container,
  .storybook-myteam-counselor-list-item .storybook-card-container {
    flex-basis: unset;
  }

  .storybook-myteam-members {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 2em;
  }

  .storybook-myteam .storybook-card-container {
    padding-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .modal-bio .storybook-modal-icon-container {
    height: 300px;
    width: 300px;
  }
}

@media (max-width: 672px) {
  .storybook-myteam-instructors-list-item .storybook-card-container,
  .storybook-myteam-counselor-list-item .storybook-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .storybook-myteam-peer-tutors div {
    order: 2;
  }
  .storybook-myteam-peer-tutors img {
    order: 1;
  }
  .storybook-modal.modal-bio .storybook-myteam-member-contact {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: flex-start;
  }
}

@media (max-width: 428px) {
  .storybook-myteam main {
    gap: 0;
  }

  .storybook-myteam h2 {
    font-size: var(--font-size-18);
    margin-bottom: 0;
  }

  .storybook-myteam h2,
  .storybook-myteam p {
    text-align: left;
  }

  .storybook-myteam .storybook-card-title {
    text-align: center;
  }

  .storybook-myteam p {
    margin-top: 0.1em;
  }

  .storybook-myteam-instructors-list-item {
    height: 530px;
    max-height: 530px;
    margin-bottom: revert;
  }

  .storybook-myteam-instructional-faculty,
  .storybook-myteam-peer-tutors,
  .storybook-myteam-academic-success-counselors {
    position: relative;
    padding: 1em 1.5em;
  }

  .storybook-myteam-instructional-faculty div,
  .storybook-myteam-peer-tutors div,
  .storybook-myteam-academic-success-counselors div {
    z-index: 2;
  }

  .storybook-myteam-instructional-faculty div p,
  .storybook-myteam-peer-tutors div p,
  .storybook-myteam-academic-success-counselors div p {
    font-size: var(--font-size-14);
  }

  .storybook-myteam-instructional-faculty h2,
  .storybook-myteam-peer-tutors h2,
  .storybook-myteam-academic-success-counselors h2 {
    font-size: var(--font-size-16);
  }

  .storybook-myteam-instructional-faculty img,
  .storybook-myteam-peer-tutors img,
  .storybook-myteam-academic-success-counselors img {
    position: absolute;
    bottom: 20px;
    right: 0;
    z-index: 1;
    max-width: 200px;
    opacity: 0.2;
  }

  .storybook-myteam-peer-tutors img {
    bottom: 8px;
    left: 10px;
  }

  .storybook-myteam-academic-success-counselors img {
    bottom: 10px;
  }

  .storybook-myteam-members .storybook-card-content,
  .storybook-myteam-members .storybook-card-content * {
    align-items: center;
  }

  .storybook-myteam-member-contact {
    flex-wrap: wrap;
  }

  .storybook-myteam-counselors-list.white-container {
    height: 300px;
  }

  .storybook-myteam-phone {
    margin-top: 0;
    padding-top: 0;
  }

  .storybook-myteam p.storybook-myteam-copy {
    text-align: center;
    padding: 0.8em 3.25em 1em 3.25em;
    font-size: var(--font-size-14);
  }

  .storybook-myteam-instructional-faculty {
    padding-top: 0;
  }

  .storybook-modal.modal-bio .storybook-modal-body {
    max-width: 100%;
  }
}
