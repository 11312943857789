.storybook-list-item--with-dropdown {
  position: relative;
}

.storybook-list--dropdown {
  display: none;
}

.storybook-list--dropdown.open {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  margin-top: 5px;
  /* border: 1px solid var(--grey-lighter); */
  background-color: var(--white-pure);
  box-shadow: 0 3px 6px var(--grey-lighter);
  border-radius: 8px;
  z-index: 9;
  max-width: 261px;
  padding: 0;
}

.storybook-list--dropdown li {
  display: block;
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.storybook-list--dropdown.open li.storybook-list-item a {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  color: var(--grey-dark);
}
.storybook-list--dropdown.open .storybook-list-item a:hover,
.storybook-list--dropdown.open .storybook-list-item a.active {
  text-decoration: none;
  background-color: var(--red);
  color: var(--white-pure);
}

.storybook-list--dropdown.open li:first-child {
  border-radius: 8px 8px 0 0;
}

.storybook-list--dropdown.open li:last-child {
  border-radius: 0 0 8px 8px;
}

.storybook-list--dropdown:active {
  display: flex;
  flex-direction: column;
}

.storybook-list-item span.active > svg {
  text-decoration: none;
  color: var(--blue);
  fill: var(--blue);
}

/* Dropdown menu style */
/* ul.storybook-list--dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: none; 
  background-color: #fff; 
  border: 1px solid #ccc; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /
  z-index: 1; 
} */

/* ul.storybook-list--dropdown li {
  padding: 8px 16px;
  cursor: pointer;
} */
