.storybook-learning .resources-left {
  margin-bottom: unset;
}

.storybook-learning .resources-left,
.storybook-learning .resources-right--inner {
  height: auto;
}

.storybook-learning .resources-right {
  margin-bottom: 20px;
}

.storybook-learning .toggle .resources-left--inner {
  height: auto;
}

.resources-content [disabled],
.resources-content .storybook-button[disabled] {
  cursor: unset;
}

@media screen and (min-width: 992px) {
  .storybook-learning .resources-right {
    height: 955px;
  }
}

@media (max-width: 991px) {
  .storybook-learning .resources-right {
    margin-bottom: 10px;
  }
}
