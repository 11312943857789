.storybook-card-container {
  width: 80%;
  max-width: 291px;
  margin: 0 auto;
  padding: 20px;
}
.storybook-card-image {
  display: flex;
  justify-content: center;
}

.storybook-card-image img {
  border-radius: 0.5em;
  width: 100%;
  max-width: 125px;
}

.storybook-card-title,
.storybook-card-content {
  text-align: center;
}

.storybook-card-title {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-heavy);
  color: var(--grey-darker);
  margin: 0.4em 0;
}

.storybook-card-content {
  font-size: var(--font-size-12);
  color: var(--grey-normal);
}

.storybook-card-content p {
  padding-top: calc(var(--font-size-12) / 2);
  margin-top: 0;
  margin-bottom: 0;
}

.storybook-card-buttons {
  display: flex;
  justify-content: center;
  margin-top: 0.625em;
}

.storybook-card-container p {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.storybook-card-content .term-date {
  display: block;
  line-height: 1.5em;
}
.storybook-card-content .term-date span {
  font-weight: var(--font-weight-bold);
}

.storybook-card-container p svg {
  fill: var(--grey-light);
  font-size: var(--font-size-18);
  line-height: 2px;
  display: inline-block;
  margin-top: 2px;
  cursor: pointer;
}

.storybook-card-container p svg:hover {
  fill: var(--red);
}

.storybook-card-container .storybook-popover-container {
  line-height: 16px;
}

@media (max-width: 320px) {
  .storybook-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1px 10px;
  }

  .storybook-card-image img {
    width: 95px;
  }

  .storybook-card-content {
    flex-basis: 55%;
  }

  .storybook-card-container p {
    display: inherit;
  }

  .storybook-card-content {
    text-align: left;
    padding: 0;
  }

  .storybook-card-title {
    font-size: var(--font-size-16);
    text-align: left;
    line-height: inherit;
  }

  .storybook-card-content p {
    font-size: var(--font-size-12);
    padding: 0;
  }
}
