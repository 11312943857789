
.skeleton {
  opacity: 0.6;
  animation: skeleton-loading 2s linear infinite alternate;
  background-color: #e6e6e6; 
  margin: 0.75rem;
  border-radius: 0.125rem;
  height: 1rem;
}

.skeleton.center {
  text-align: center;
  margin: 0 auto;
}

.skeleton.title {
  height: 1.75rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.skeleton.text {
  height: .75rem;
  margin-bottom: 0.75em;
  margin-top: 0.75rem;
}

.skeleton.text:last-child {
  margin-bottom:0;
  /* width: 80%; */
}

.height-100 {
  height: 100%;
}

.height-50 {
  height: 50%;
}

.height-1x {
  height: 1rem;
}

.height-2x {
  height: 2em;
}

.width-100 {
  width: 97%;
}

.width-75 {
  width: 75%;
}

.width-40 {
  width: 40%;
}

.skeleton.profile-circle {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.skeleton.profile-rectangle {
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.skeleton.profile-rectangle--small {
  height: 60px;
  width: 60px;
  margin: 0 auto;
}

.skeleton.profile-circle--small {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.skeleton.flexbox {
  display: flexbox;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.align-left {
  align-items: left;
}

.align-right {
  align-items: right;
} 

.skeleton.flex-basis-100 {
  flex-basis: 98%;
}

.storybook-app-badge.flex-basis-15 {
  flex-basis: 15%;
}

.margin-1x {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.margin-2x {
  margin-top: 2rem;
}

.skeleton.margin-top-1x {
  margin-top: 1rem;
}

.skeleton.margin-top-2x {
  margin-top: 2rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* Overrides */
.skeleton-container .storybook-dashboard-message.align-left {
  align-items: left !important;
}