body {
  margin: 0;
}

.underline {
  text-decoration: underline;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}