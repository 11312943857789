.storybook-app-badge {
  cursor: pointer;
  color: var(--grey-dark);
  font-size: var(--font-size-12);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}

.storybook-app-badge:hover {
  text-decoration: none;
  color: var(--red);
}

.storybook-app-badge[disabled] {
  color: var(--grey-normal);
  position: relative;
  cursor: auto;
}

.storybook-app-badge[disabled] img {
  opacity: 0.3;
}

.storybook-app-badge img {
  margin-bottom: 8px;
  width: 40px;
  height: 40px;
}

.storybook-app-badge[disabled] span {
  color: var(--grey);
  pointer-events: none;
}

.storybook-app-badge[disabled]
  .storybook-app-badge--copy
  span
  + span.hover-tip {
  background-color: var(--cream-light);
  cursor: text;
  display: none;
  top: -35px;
  padding: 5px 10px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 2px 4px #00000029;
  font-size: 12px;
  border: 1px solid var(--tt-border-color);
  left: -15%;
  text-wrap: nowrap;
}

.storybook-app-badge[disabled]
  .storybook-app-badge--copy
  span
  + span.hover-tip::before {
  background-color: var(--cream-light);
  content: "";
  display: block;
  height: 8px;
  box-shadow: 2px 2px 2px #00000012;
  position: absolute;
  width: 8px;
  bottom: -4px;
  left: 60%;
  margin-left: -17px;
  transform: rotate(45deg);
}
.storybook-app-badge[disabled]
  .storybook-app-badge--copy
  span
  + span.hover-tip::after {
  content: "";
  display: block;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
}

.storybook-app-badge[disabled]
  .storybook-app-badge--copy:hover
  span
  + span.hover-tip,
.storybook-app-badge[disabled]:hover
  img:hover
  + .storybook-app-badge--copy
  span.hover-tip {
  display: block;
  top: -20px;
  left: 35%;
  transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 767px) {
  .storybook-app-badge {
    flex-direction: row;
    text-align: left;
    width: 100%;
  }

  .storybook-app-badge img {
    margin-right: 8px;
    margin-bottom: 0;
    height: 32px;
    width: 32px;
  }
}

@media (max-width: 428px) {
  .storybook-app-badge {
    flex-direction: column;
    text-align: center;
    width: 32%;
  }

  .storybook-app-badge img {
    margin-right: 0;
    margin-bottom: 8px;
    height: 32px;
    width: 32px;
  }

  .storybook-app-badge span {
    white-space: nowrap;
  }

  .storybook-dashboard-myapps .storybook-app-badge span {
    max-width: 80px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
