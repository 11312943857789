.storybook-task-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  width: 100px;
  min-height: 100px;
}

.storybook-task-item .item-icon {
  background-color: var(--white-pure);
  border-radius: 50%;
  box-sizing: border-box;
  margin-bottom: 6px;
  height: 48px;
  width: 48px;
}

.storybook-task-item .item-icon img {
  max-width: 100%;
  width: 48px;
  height: auto;
}

.storybook-task-item .item-icon span.hover-tip {
  background-color: var(--white-pure);
  cursor: text;
  display: none;
  top: -15px;
  left: -12%;
  padding: 5px 10px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 12px;
  color: #555555;
  font-weight: 300;
  border: 1px solid var(--tt-border-color);
  text-wrap: nowrap;
}

.storybook-task-item .item-icon span.hover-tip::before {
  background-color: var(--white-pure);
  content: "";
  display: block;
  height: 8px;
  box-shadow: 2px 2px 2px #00000012;
  position: absolute;
  width: 8px;
  bottom: -4px;
  left: 60%;
  margin-left: -17px;
  transform: rotate(45deg);
}
.storybook-task-item .item-icon + span.hover-tip::before {
  content: "";
  display: block;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
}

.storybook-task-item--default .item-icon {
  border: 2px solid var(--border-color);
}

.storybook-task-item--default.completion .item-icon {
  margin-bottom: 17px;
}

.storybook-task-item--default.completion .item-icon {
  height: 36px;
  width: 36px;
}

.storybook-task-item--started-hover.completion .item-icon,
.storybook-task-item--started.completion .item-icon {
  height: 72px;
  width: 72px;
}

.storybook-task-item--progress.completion .item-icon {
  height: 72px;
  width: 66px;
}

.storybook-task-item--done.completion .item-icon {
  height: 54px;
  width: 54px;
}

.storybook-task-item--success.completion .item-icon {
  height: 64px;
  width: 64px;
}

.storybook-task-item--success.onboarding .item-icon {
  margin-top: 2px;
}

.storybook-task-item .item-title p {
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-18);
  margin: 0;
}

.storybook-task-item--in-progress .item-icon img {
  position: relative;
  top: -4px;
}

.storybook-task-item--in-progress .item-title p,
.storybook-task-item--started-hover .item-title p,
.storybook-task-item--started .item-title p {
  color: var(--red);
  font-weight: var(--font-weight-bold);
}

.storybook-task-item--passed .item-title p,
.storybook-task-item--done .item-title p,
.storybook-task-item--success .item-title p {
  color: var(--green-text);
}

.storybook-task-item--passed .item-title p,
.storybook-task-item--success .item-title p {
  font-weight: var(--font-weight-bold);
}

.storybook-task-item--default .item-title p {
  color: var(--grey-dark);
}

.storybook-task-item--started,
.storybook-task-item--in-progress {
  cursor: pointer;
}
.storybook-task-item--started .item-icon img {
  display: none;
}
.storybook-task-item--started .item-icon {
  border: 0.1423em solid var(--red);
}
.storybook-task-item--started:hover .item-icon img {
  display: block;
}

.storybook-task-item--started:hover .item-icon {
  border: none;
}

.storybook-task-item--started:hover {
  text-decoration: underline;
  text-decoration-color: var(--red);
  text-underline-offset: 0.25em;
}

.storybook-task-item--started .item-pending,
.storybook-task-item--in-progress .item-pending {
  color: var(--red);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-bold);
  width: 100%;
  text-align: center;
  position: absolute;
  top: -38px;
}

.storybook-task-item--started .item-pending:after,
.storybook-task-item--in-progress .item-pending:after {
  background-color: var(--red);
  bottom: -14px;
  height: 10px;
  position: absolute;
  left: calc(50% - 1px);
  width: 2px;
}

#anchor--components-taskprogressitem--default .innerZoomElementWrapper > * {
  border-width: 0 !important;
}

#anchor--components-taskprogressitem--default .docs-story,
#anchor--components-taskprogressitem--default .docs-story > div:first-child {
  height: 280px;
}

.storybook-task-item--default .item-icon img {
  display: block;
  padding: 10px;
}

@media only screen and (min-width: 768px) {
  .storybook-task-item--started .item-pending:after,
  .storybook-task-item--in-progress .item-pending:after {
    content: "";
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .storybook-task-item--started .item-pending:after,
  .storybook-task-item--in-progress .item-pending {
    font-size: var(--font-size-10);
    top: -24px;
  }
  .storybook-task-item .item-icon span.hover-tip {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  /* Your mobile styles here */
  .storybook-task-item {
    width: 100%;
    flex-direction: row;
    min-height: revert;
  }
  .storybook-task-item .item-icon {
    margin-right: 1em;
    margin-bottom: 0;
  }
  .storybook-task-item .item-title {
    text-align: left;
  }
  .storybook-task-item .item-title p {
    display: inline-block;
  }
  .storybook-task-item p:first-child {
    margin-right: 0.25em;
  }
  .storybook-task-item--started .item-pending,
  .storybook-task-item--in-progress .item-pending {
    bottom: 0;
    left: 64px;
    top: auto;
    width: fit-content;
  }
}
