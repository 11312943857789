.accordion {
  background: var(--white-pure);
  border-radius: 8px;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 20px 20px 5px;
  margin-bottom: 40px;
}

.accordion > h4 {
  color: var(--blue-purple);
  margin-top: 0;
}

.accordion-title {
  border: none;
  background: unset;
  width: unset;
  border-top: 1px solid var(--grey-disabled);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px 20px 10px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

.accordion-title:focus > h4 {
  color: var(--red);
}

.accordion-item:last-child .accordion-title {
  border-bottom: 1px solid var(--grey-disabled);
}

.accordion-item.active .accordion-title,
.accordion-title:hover,
.accordion-title:focus {
  background-color: #eeeeee;
}

.accordion-title > h4 {
  color: var(--grey-darker);
  margin: 0;
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-20);
  transition: all 0.3s ease-in-out;
}

.accordion-title:hover > h4 {
  color: var(--red);
}

.accordion-title img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  transition: all 0.2s ease-in-out;
  width: 10px;
}

.accordion-title:hover > img {
  filter: brightness(0) saturate(100%) invert(33%) sepia(87%) saturate(5296%)
    hue-rotate(16deg) brightness(97%) contrast(94%);
}

.theme-cold .accordion-title:hover > img {
  filter: brightness(0) saturate(100%) invert(24%) sepia(24%) saturate(6036%)
    hue-rotate(176deg) brightness(91%) contrast(95%);
}

.accordion-content {
  border-top: 1px solid var(--grey-disabled);
  color: var(--grey-dark);
  padding: 20px;
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-20);
  display: none;
}

.accordion-item.active .accordion-content {
  display: block;
}

.accordion-item.active .accordion-title img {
  transform: translateY(-50%) rotate(180deg);
}

@media (min-width: 768px) {
  .accordion-title {
    padding: 20px 40px 20px 20px;
  }

  .accordion-title img {
    right: 20px;
  }

  .accordion-content {
    padding: 25px 30px;
    font-size: var(--font-size-14);
    line-height: var(--line-spacing-24);
  }
}
