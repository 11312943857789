
.storybook-team.white-container {
  background: var(--white-pure) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--grey-lighter);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 50px;
}

.storybook-team > h2 {
  font-size: 18px;
  color: var(--blue-purple);
  width: auto;
}

.storybook-team .storybook-card-title {
  margin: 0.12em 0;
}

.storybook-team hr {
  margin-inline: 0.21rem;
  border:0;
  border-top: 1px double var(--grey-lighter);
}

.storybook-team .storybook-card-container {
  max-width: 100%;
  width: 100%;
}

.storybook-team--cards {
  display: flex;
}

.storybook-team .storybook-card-content > * {
  color: var(--grey);
  text-align: left;
  justify-content: unset;
  padding-top: unset;
}

.storybook-team .storybook-card-container p {
  justify-content: flex-start;
}

.storybook-teammember--contacts {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.storybook-teammember--contacts p {
  padding: 0;
}

.storybook-teammember--contacts a {
  align-items: center;
  color: var(--grey);
  display: flex;
  gap: 5px;
  text-decoration: none;
}

.storybook-teammember--contacts a:hover {
  color: var(--red);
}

.storybook-teammember--contacts a:hover img {
  filter: brightness(0) saturate(100%) invert(31%) sepia(64%) saturate(1747%) hue-rotate(354deg) brightness(95%) contrast(102%);
}

.storybook-team .storybook-card-content h2 {
  color: var(--grey-darker);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-spacing-23);
}

.storybook-team .storybook-card-image {
  justify-content: flex-start;
  margin-bottom: 10px;
}

.storybook-team .storybook-card-image img {
  border-radius: 8px;
  object-fit: cover;
  height: 64px;
  width: 64px;
}

.storybook-team .storybook-teammember-slack {
  color: unset;
  width: fit-content;
}

@media screen and (min-width: 992px) {
  .storybook-team .storybook-card-container {
    padding: 0 20px;
  }

  .storybook-team .storybook-card-container:first-child {
    padding-left: 20px;
  }

  .storybook-team .storybook-card-container:not(:last-child) {
    border-right: 1px solid var(--button-bg-disabled);
  }
}

@media screen and (min-width: 1200px) {
  .storybook-team .storybook-card-container {
    padding: 0 40px;
  }
}

@media screen and (max-width: 991px) {
  .storybook-team--cards {
    flex-direction: column;
  }

  .storybook-team .storybook-card-container {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    gap: 20px;
    border-top: 1px solid var(--button-bg-disabled);
  }

  .storybook-team .storybook-card-content h2 {
    margin-top: 0;
  }
  
  .storybook-team .storybook-card-image {
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  .storybook-team .storybook-card-content > * {
    font-size: var(--font-size-12);
  }
}