.storybook-textarea textarea {
  border: 1px solid var(--grey-disabled);
  border-radius: 4px;
  box-sizing: border-box; 
  color: var(--grey-darker);
  padding: 10px;
  font: 14px var(--font-family-lato);
  letter-spacing: 0px;
  resize: vertical;
  margin-bottom: 10px;
  min-height: 150px;
  max-height: 500px;
  width: 100%;
}

.storybook-textarea-btn-area {
  text-align: center;
}

.storybook-textarea-btn-area button {
  width: 120px;
}