.modal-intended-program {
  text-align: center;
}

.modal-intended-program .flex {
  align-items: center;
}

.modal-intended-program p a {
  font-weight: 600;
}

.modal-intended-program label {
  margin-bottom: 10px;
}

.modal-intended-program select {
  border: 1px solid var(--grey-disabled);
  border-radius: 4px;
  color: var(--grey-light);
  font-weight: 500;
  padding: 10px 20px;
  max-width: 315px;
  margin-bottom: 10px;
  height: 38px;
  width: 100%;
}

.modal-intended-program .select-program {
  margin-bottom: 20px;
}

.modal-intended-program select:focus-visible {
  outline: none;
}

.modal-intended-program button {
  max-width: 210px;
  margin-bottom: 20px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .modal-intended-program h2.storybook-modal-title {
    font-size: 16px;
  }

  .modal-intended-program .storybook-modal-icon-container img {
    max-width: 150px;
  }
}