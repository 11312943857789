.wellness .page-title p {
  white-space: pre-line;
  white-space: pre-wrap;
}

.wellness-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
}

.wellness-col {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.wellness-col img {
  max-width: 250px;
  width: 100%;
}

.wellness-col p {
  color: var(--grey-dark);
  line-height: var(--line-spacing-20);
  margin: 0;
  white-space: pre-wrap;
}

.wellness-message {
  margin: 15px 0 0 0;
}

.wellness-message svg {
  cursor: pointer;
  font-size: 16px;
}

@media (min-width: 768px) {
  .card {
    padding: 30px 11%;
    margin-bottom: 20px;
  }

  .card .storybook-button {
    margin-top: 15px;
  }

  .wellness .storybook-button {
    max-width: 200px;
    width: 100%;
  }

  .wellness-col {
    flex-direction: column;
    padding: 0 55px;
    text-align: center;
    width: 33.333333%;
  }

  .wellness-col:not(:last-child) {
    border-right: 1px solid var(--grey-disabled);
  }

  .wellness-col h3 {
    margin: 10px 0;
  }

  .wellness-row {
    flex-direction: row;
    margin: 30px 0;
  }
}

@media (max-width: 767px) {
  .wellness > h2 {
    font-size: var(--font-size-18);
    margin-bottom: 10px;
  }

  .wellness > p {
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-20);
    margin-top: 0;
  }

  .wellness-row {
    padding: 10px 0;
  }

  .wellness-col {
    gap: 10px;
    margin-bottom: 20px;
  }

  .wellness-col h3 {
    font-size: var(--font-size-14);
    line-height: var(--line-spacing-20);
    margin-bottom: 5px;
  }

  .wellness-col p {
    font-size: var(--font-size-12);
  }

  .wellness-col img {
    width: 35%;
  }

  .card h2 {
    font-size: var(--font-size-16);
    line-height: var(--line-spacing-20);
  }

  .card p {
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-20);
  }
}
