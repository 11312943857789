.storybook-avatar-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1rem;
  max-width:400px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.storybook-avatar-list img {
  max-width: 76px;
  cursor: pointer;
  border:2px solid transparent;
}
.storybook-avatar-list img:hover,
.storybook-avatar-list img.active {
  border: 2px solid var(--red);
  border-radius: 8px;
}

@media (min-width: 767px) {
  .storybook-avatar-list {
    gap: 3.125rem;
  }
  .storybook-avatar-list img {
    max-width: unset;
  }
}